<template>
  <div class="terminos">
    <div class="container">
      <div class="row">
        <div class="col-8 col-md-6 text-left mb-3 mb-md-0">
          <p>{{ contact.address }}</p>
          <p>{{ currentYear }} ACARA - Todos los derechos reservados.</p>
        </div>
        <div class="col-4 col-md-6 text-md-right social-icons text-end">
          <a 
            v-for="(platform, index) in socialPlatforms" 
            :key="index" 
            :href="social[platform]" 
            target="_blank"
          >
            <i :class="iconClasses[platform]" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegalSection",
  props: {
    contact: Object,
    social: Object,
    currentYear: Number
  },
  data() {
    return {
      socialPlatforms: ['instagram', 'facebook', 'twitter', 'linkedin'],
      iconClasses: {
        instagram: 'fab fa-instagram',
        facebook: 'fab fa-facebook-f',
        twitter: 'fab fa-twitter',
        linkedin: 'fab fa-linkedin-in' 
      }
    };
  }
};
</script>

<style scoped>
* {
  font-family: 'Roboto', sans-serif;
}

.terminos {
  background: #011f5b;
  color: #6db2d9;
  padding: 30px 0 20px 0;
}

.terminos p {
  font-family: Inter;
  font-weight: 500;
  line-height: 29.05px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

@media (min-width: 768px) {
  .terminos p {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .terminos p {
    font-size: 12px;
  }
}

.social-icons i {
  margin-right: 20px;
}

.social-icons a {
  margin-right: 20px;
  color: #6db2d9;
}

.social-icons a:hover {
  color: #ffffff;
}
</style>