<template>
  <div
    v-if="isVisible"
    class="modal-backdrop d-flex align-items-center justify-content-center"
  >
    <div
      class="modal-container bg-white shadow-lg animate__animated animate__fadeIn"
    >
      <div class="header w-100">
        <button @click="closeModal" class="back-button">
          <i class="fas fa-times"></i>
        </button>
        <h1 class="modal-title">Editar Sucursal</h1>
      </div>
      <div class="modal-content p-4">
        <div v-if="!isLoading" class="concessionaire-form-container">
          <div
            class="form-section informacion-principal"
            data-section-title="Información Principal"
          >
            <label for="cuit" class="form-label">CUIT</label>
            <input
              type="text"
              id="cuit"
              class="form-control form-control-lg"
              :value="formData.cuit"
              disabled
            />
            <span v-if="formErrors.cuit" class="text-danger error-message">
              <i class="fas fa-exclamation-circle"></i> {{ formErrors.cuit }}
            </span>

            <div class="form-group custom-width">
              <label for="central_house" class="form-label">Casa Central</label>
              <input
                type="text"
                id="central_house"
                class="form-control form-control-lg"
                :value="concessionaire?.central_house?.business_name"
                disabled
              />
            </div>
          </div>
          <div class="separator-line my-4"></div>
          <div class="form-section" data-section-title="Datos Generales">
            <div class="form-row">
              <div class="form-group col">
                <label for="business_name" class="form-label">
                  Razón Social <span class="required">*</span>
                </label>
                <input
                  type="text"
                  id="business_name"
                  class="form-control form-control-lg"
                  v-model="formData.business_name"
                />
                <span
                  v-if="formErrors.business_name"
                  class="text-danger error-message"
                >
                  <i class="fas fa-exclamation-circle"></i>
                  {{ formErrors.business_name }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="form-section relationship-fields"
            data-section-title="Ubicación"
          >
            <div class="form-group relationship-select">
              <label for="province_id">
                Provincia <span class="required">*</span>
              </label>
              <select
                id="province_id"
                class="form-control"
                v-model="formData.province_id"
                :disabled="!formData.province_id || isLoadingLocalities"
              >
                <option value="">Seleccionar Provincia</option>
                <option
                  v-for="province in provinces"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>
              <span v-if="formErrors.province_id" class="text-danger">{{
                formErrors.province_id
              }}</span>
            </div>

            <div class="form-group relationship-select">
              <label for="locality_id">
                Localidad <span class="required">*</span>
              </label>
              <select
                id="locality_id"
                class="form-control"
                v-model="formData.locality_id"
                :disabled="!formData.province_id"
              >
                <option value="">Seleccionar Localidad</option>
                <option
                  v-for="locality in localities"
                  :key="locality.id"
                  :value="locality.id"
                >
                  {{ locality.name }}
                </option>
              </select>
              <span v-if="formErrors.locality_id" class="text-danger">{{
                formErrors.locality_id
              }}</span>
            </div>

            <div class="form-group relationship-select">
              <label for="regional_id">
                Regional <span class="required">*</span>
              </label>
              <select
                id="regional_id"
                class="form-control"
                v-model="formData.regional_id"
                :disabled="!formData.province_id || isLoadingRegionals"
              >
                <option value="">Seleccionar Regional</option>
                <option
                  v-for="regional in regionals"
                  :key="regional.id"
                  :value="regional.id"
                >
                  {{ regional.name }}
                </option>
              </select>
              <span v-if="formErrors.regional_id" class="text-danger">{{
                formErrors.regional_id
              }}</span>
            </div>
          </div>
          <div
            class="form-section datos-ubicacion"
            data-section-title="Dirección"
          >
            <div class="form-row">
              <div class="form-group col">
                <label for="street_name">Dirección</label>
                <input
                  type="text"
                  id="street_name"
                  class="form-control"
                  v-model="formData.street_name"
                />
                <span v-if="formErrors.street_name" class="text-danger">{{
                  formErrors.street_name
                }}</span>
              </div>

              <div class="form-group col">
                <label for="street_number">Número</label>
                <input
                  type="text"
                  id="street_number"
                  class="form-control"
                  v-model="formData.street_number"
                />
                <span v-if="formErrors.street_number" class="text-danger">{{
                  formErrors.street_number
                }}</span>
              </div>

              <div class="form-group col">
                <label for="postal_code">Código Postal</label>
                <input
                  type="text"
                  id="postal_code"
                  class="form-control"
                  v-model="formData.postal_code"
                />
                <span v-if="formErrors.postal_code" class="text-danger">{{
                  formErrors.postal_code
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="form-section datos-contacto"
            data-section-title="Datos de Contacto"
          >
            <div class="form-row">
              <div class="form-group col">
                <label for="phone">Teléfono</label>
                <input
                  type="text"
                  id="phone"
                  class="form-control"
                  v-model="formData.phone"
                />
                <span v-if="formErrors.phone" class="text-danger">{{
                  formErrors.phone
                }}</span>
              </div>

              <div class="form-group col">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  v-model="formData.email"
                />
                <span v-if="formErrors.email" class="text-danger">{{
                  formErrors.email
                }}</span>
              </div>
            </div>
          </div>

          <!-- Modificación en el template -->
          <div class="form-section" data-section-title="Especialización">
            <div class="form-row">
              <div class="form-group col">
                <label for="sector">Rubro</label>
                <select
                  id="sector"
                  class="form-control"
                  v-model="formData.sector"
                  :disabled="isLoading"
                  @change="handleVehicleTypeChange"
                >
                  <option value="">Seleccionar Rubro</option>
                  <option
                    v-for="type in vehicleTypes"
                    :key="type.id"
                    :value="type.id"
                  >
                    {{ type.name }}
                  </option>
                </select>
                <span v-if="formErrors.sector" class="text-danger">
                  {{ formErrors.sector }}
                </span>
              </div>

              <div class="form-group col">
                <label for="brands">Marcas</label>
                <multiselect
                  v-model="formData.brands"
                  :options="brandsOptions"
                  :multiple="true"
                  :disabled="!formData.sector || isLoading"
                  placeholder="Seleccione una o más marcas"
                  label="name"
                  track-by="id"
                  :preserve-search="true"
                >
                  <template #option="{ option }">
                    <div>
                      {{ option.name }}
                    </div>
                  </template>
                </multiselect>
                <span v-if="formErrors.brands" class="text-danger">
                  {{ formErrors.brands }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="form-actions d-flex gap-3 justify-content-end mt-4 pt-3 border-top"
          >
            <button
              class="btn btn-outline-secondary px-4 py-2"
              @click="closeModal"
            >
              Cancelar
            </button>
            <button
              class="btn btn-primary px-4 py-2"
              @click="submitForm"
              :disabled="isSubmitting"
            >
              <span v-if="isSubmitting">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                Guardando...
              </span>
              <span v-else>Guardar Cambios</span>
            </button>
          </div>
        </div>

        <div v-else class="loading-container">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
          <p class="mt-3">Cargando datos de la sucursal...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, nextTick } from "vue";
import Multiselect from "vue-multiselect";

import {
  fetchProvinces,
  fetchAllLocalities,
  fetchProvinceLocalities,
  fetchProvinceRegionals,
} from "@/services/api/provinceService";
import { fetchRegionals } from "@/services/api/regionalService";

import { useFormValidator } from "@/composables/useFormValidator";
import { updateConcessionaire } from "@/services/api/concessionaire.js";

import { fetchBrandsByVehiculeType } from "@/services/api/brandService";
import { vehicleTypes } from "@/services/api/vehicleTypes";

export default {
  components: {
    Multiselect,
  },
  props: {
    isVisible: Boolean,
    concessionaire: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["close", "success"],
  setup(props, { emit }) {
    const isLoadingLocalities = ref(false);
    const isLoadingRegionals = ref(false);

    const vehicleTypesList = vehicleTypes;
    const isLoading = ref(true);
    const isSubmitting = ref(false);

    const formData = reactive({
      cuit: "",
      member_number: "",
      business_name: "",
      province_id: "",
      locality_id: "",
      regional_id: "",
      street_name: "",
      street_number: "",
      postal_code: "",
      phone: "",
      email: "",
      sector: "",
      brands: [],
    });

    const formErrors = reactive({});
    const { setValidations, validate } = useFormValidator(formData, formErrors);
    const provinces = ref([]);
    const localities = ref([]);
    const regionals = ref([]);
    const brandsOptions = ref([]);

    const loadInitialData = async () => {
      try {
        isLoading.value = true;

        const [provincesRes] = await Promise.all([fetchProvinces()]);
        provinces.value = provincesRes.data;

        if (!props.concessionaire) return;

        // Cargar datos principales
        const mainFields = [
          "id",
          "cuit",
          "business_name",
          "province_id",
          "locality_id",
          "regional_id",
          "street_name",
          "street_number",
          "postal_code",
          "phone",
          "email",
          "sector",
        ];

        mainFields.forEach((key) => {
          formData[key] = props.concessionaire[key] || "";
        });

        // datos relacionados
        await loadRelatedData();
        await processBrands();

        // localidades y regionales si hay provincia
        if (formData.province_id) {
          await loadLocalitiesAndRegionals(formData.province_id);
          formData.locality_id = props.concessionaire.locality_id;
          formData.regional_id = props.concessionaire.regional_id;
        }
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const loadRelatedData = async () => {
      if (props.concessionaire.central_house) {
        formData.central_house_business_name =
          props.concessionaire.central_house.business_name;
      }
    };

    const processBrands = async () => {
      if (!props.concessionaire?.sector) return;

      formData.sector = props.concessionaire.sector;
      brandsOptions.value = await fetchBrandsByVehiculeType(formData.sector);

      if (props.concessionaire.brands?.length) {
        formData.brands = props.concessionaire.brands
          .map((brand) =>
            brandsOptions.value.find((b) => b.id === brand.brand_id)
          )
          .filter(Boolean);
      }
    };

    const loadLocalitiesAndRegionals = async (provinceId = null) => {
      try {
        isLoadingLocalities.value = true;
        isLoadingRegionals.value = true;

        if (provinceId) {
          const [localitiesRes, regionalsRes] = await Promise.all([
            fetchProvinceLocalities(provinceId),
            fetchProvinceRegionals(provinceId),
          ]);
          localities.value = localitiesRes.data;
          regionals.value = regionalsRes.data;
        } else {
          const [allLocalities, allRegionals] = await Promise.all([
            fetchAllLocalities(),
            fetchRegionals(),
          ]);
          localities.value = allLocalities.data;
          regionals.value = allRegionals.data;
        }
      } catch (error) {
        console.error("Error cargando datos:", error);
      } finally {
        isLoadingLocalities.value = false;
        isLoadingRegionals.value = false;
      }
    };

    const requiredFields = [
      "business_name",
      "province_id",
      "locality_id",
      "regional_id",
      "sector",
      "street_name",
      "street_number",
      "postal_code",
      "phone",
      "email",
    ];

    const customValidators = {
      cuit: (value) => {
        if (!/^\d{11}$/.test(value)) return "El CUIT debe tener 11 dígitos";
        return null;
      },
      email: (value) => {
        if (value && !/^\S+@\S+\.\S+$/.test(value)) return "Email inválido";
        return null;
      },
      postal_code: (value) => {
        if (value && !/^\d{4,8}$/.test(value)) return "Código postal inválido";
        return null;
      },
      brands: (value) => {
        if (value.length === 0) return "Debe seleccionar al menos una marca";
        return null;
      },
    };

    setValidations(requiredFields, customValidators);

    const validateForm = () => validate();

    const submitForm = async () => {
      if (!validateForm()) return;
      isSubmitting.value = true;
      try {
        const concessionaireData = {
          member_number: formData.member_number,
          business_name: formData.business_name,
          province_id: formData.province_id,
          locality_id: formData.locality_id,
          regional_id: formData.regional_id,
          street_name: formData.street_name,
          street_number: formData.street_number,
          postal_code: formData.postal_code,
          phone: formData.phone,
          email: formData.email,
          sector: formData.sector,
          brands: formData.brands.map((brand) => brand.id),
        };

        if (props.concessionaire) {
          await updateConcessionaire(
            props.concessionaire.id,
            concessionaireData
          );
        } else {
          // Creacion pero por ahora NO
        }
        const successMessage = "Usuario actualizado correctamente.";
        emit("success", successMessage);
        closeModal();
      } catch (error) {
        console.error("Error saving concessionaire:", error);
        if (error.response?.data?.errors) {
          Object.assign(formErrors, error.response.data.errors);
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    const handleVehicleTypeChange = async () => {
      formData.brands = [];
      brandsOptions.value = [];

      if (!formData.sector) return;

      try {
        brandsOptions.value = await fetchBrandsByVehiculeType(formData.sector);

        if (!formData.brands?.value) {
          formData.brands.value = [];
        }
        formData.brands.value = formData.brands.value.filter(
          (brand) => brand.sector === formData.sector
        );
      } catch (error) {
        console.error("Error al obtener lista de marcas:", error);
        brandsOptions.value = [];
        formData.brands.value = [];
      }
    };

    const closeModal = () => {
      Object.keys(formData).forEach((key) => (formData[key] = ""));
      formData.brands = [];
      formErrors.value = {};
      isLoading.value = true;
      isSubmitting.value = false;
      emit("close");
    };

    watch(
      () => props.concessionaire,
      async (newVal) => {
        if (newVal) {
          await loadInitialData();

          if (formData.regional_id) {
            await nextTick();
            formData.regional_id = props.concessionaire.regional_id;
          }
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => formData.province_id,
      (newVal) => {
        if (newVal) {
          formData.locality_id = "";
          formData.regional_id = "";
          loadLocalitiesAndRegionals(newVal);
        } else {
          localities.value = [];
          regionals.value = [];
        }
      }
    );

    watch(
      () => props.isVisible,
      (newVal) => {
        if (newVal) {
          loadInitialData();
        } else {
          closeModal();
        }
      }
    );

    return {
      handleVehicleTypeChange,
      isLoadingLocalities,
      isLoadingRegionals,
      isLoading,
      isSubmitting,
      formData,
      formErrors,
      provinces,
      localities,
      regionals,
      vehicleTypes: vehicleTypesList,
      brandsOptions,
      submitForm,
      closeModal,
    };
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
}

.modal-container {
  width: 95%;
  max-width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  font-family: "Inter", sans-serif;
  animation-duration: 0.3s;
  padding: 0;
}

.modal-content {
  padding: 0;
}

.modal-title {
  font-size: 2rem;
  font-weight: 600;
  color: #ecf0f1;
  margin: 0;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.back-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background: none;
  color: #6c757d;
  transition: all 0.2s ease;
}

.back-button:hover {
  color: #0d6efd;
  transform: rotate(90deg);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.75rem;
  font-size: 0.925rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.form-control-lg {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;
  transition: border-color 0.2s ease;
}

.form-control-lg:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.required {
  color: #dc3545;
  font-size: 0.9em;
}

.separator-line {
  height: 1px;
  background-color: #e9ecef;
  margin: 2rem 0;
}

.error-message {
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.loading-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.btn {
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-outline-secondary {
  border-color: #6c757d;
  color: #6c757d;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
  color: #fff;
}

@media (max-width: 768px) {
  .modal-container {
    width: 100%;
    margin: 1rem;
  }

  .form-row > .col {
    margin-bottom: 1rem;
  }

  .form-actions {
    flex-direction: column-reverse;
    gap: 0.75rem;
  }
}

.back-button {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 1.25rem;
  cursor: pointer;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transform: scale(1.1);
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  padding: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0;
  position: -webkit-sticky;
  backdrop-filter: blur(10px);
}

.back-button {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 1.25rem;
  cursor: pointer;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transform: scale(1.1);
}

.modal-title {
  font-size: 2rem;
  font-weight: 600;
  color: #ecf0f1;
  margin: 0;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.modal-container {
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
}

.modal-container::-webkit-scrollbar {
  width: 8px;
}

.modal-container::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.modal-container::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 4px;
  border: 2px solid #2d3748;
}

.modal-container::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.relationship-fields {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e9ecef;
  position: relative;
}

.relationship-fields::before {
  position: absolute;
  top: -10px;
  left: 20px;
  background: white;
  padding: 0 8px;
  color: #6c757d;
  font-size: 0.9rem;
  font-weight: 500;
}

.relationship-select {
  position: relative;
  transition: all 0.3s ease;
}

.relationship-select label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #2d3748;
}

.relationship-select select {
  border: 2px solid #dee2e6;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.relationship-select select:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select-icon {
  width: 24px;
  height: 24px;
  color: #4a5568;
}

select:disabled {
  background-color: #f8f9fa;
  opacity: 0.7;
  cursor: not-allowed;
}

select:disabled + label {
  opacity: 0.7;
}

.relationship-select:not(:has(select:disabled)):hover select {
  border-color: #adb5bd;
}

#province_id {
  border-color: #4a9cff;
}

#locality_id {
  border-color: #6fcf97;
}

#regional_id {
  border-color: #f2994a;
}

.text-danger {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  padding: 4px 8px;
  background: #fff5f5;
  border-radius: 4px;
  width: fit-content;
}

.text-danger i {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .relationship-fields {
    padding: 1rem;
  }

  .relationship-select select {
    padding: 0.6rem 0.8rem;
    background-position: right 0.8rem center;
  }
}

.form-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e9ecef;
  position: relative;
}

.form-section::before {
  content: attr(data-section-title);
  position: absolute;
  top: -12px;
  left: 15px;
  background: #f8f9fa;
  padding: 0 10px;
  color: #2d3748;
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.informacion-principal {
  padding-top: 2rem;
}

.datos-contacto .form-row,
.datos-ubicacion .form-row {
  margin-bottom: 1rem;
}

.form-control {
  border: 2px solid #dee2e6;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #4a5568;
}

@media (max-width: 768px) {
  .form-section {
    padding: 1rem;
  }

  .form-section::before {
    left: 15px;
    font-size: 0.8rem;
  }
}
</style>