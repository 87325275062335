<template>
  <div class="evento-card" v-if="conference">
    <EventoImageCard
      class="evento-image-card"
      :primary-color="primaryColor"
      :secondary-color="secondaryColor"
      :image="conference.image"
      :alt-text="altText"
      :style="{ order: isEven ? 0 : 1 }"
      :isEven="isEven"
    />
    <EventoContentCard
      class="evento-content-card"
      :primary-color="primaryColor"
      :style="{ order: isEven ? 1 : 0 }"
      :audience-target="audienceTarget"
      @subscribe-click="handleSubscribe"
      @more-info-click="openModal"
      v-bind="contentProps"
    />
    <ConferenceModal
      v-if="isModalVisible"
      :is-visible="isModalVisible"
      :image="conference.image"
      :name="conference.name"
      :start_time="conference.start_time"
      :description="conference.information"
      @close="closeModal"
    />
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import EventoImageCard from "./EventoImageCard.vue";
import EventoContentCard from "./EventoContentCard.vue";
import ConferenceService from "@/services/api/conference";
import ConferenceModal from "./ConferenceModal.vue";

const props = defineProps({
  conference: {
    type: Object,
    required: true,
    validator: (conf) => ["name", "start_time"].every((key) => key in conf),
  },
  conferenceId: {
    type: [Number, String],
    required: true,
  },
  primaryColor: {
    type: String,
    default: "#57025b",
  },
  secondaryColor: {
    type: String,
    default: "#9760C7",
  },
  audienceTarget:{
    type: String,
    default: "",
  },
  index: {
    type: Number,
    required: true,
  },
  registrationEndDate: String,
});

const store = useStore();

const isModalVisible = ref(false);

const concessionaireId = computed(
  () => store.getters.getSelectedConcessionaire?.id
);

const isEven = computed(() => props.index % 2 === 0);

const altText = computed(() => 
  props.conference?.name 
    ? `Imagen de la conferencia: ${props.conference.name}`
    : 'Imagen de la conferencia'
);

const contentProps = computed(() => ({
  name: props.conference.name,
  start_time: props.conference.start_time,
  end_time: props.conference.end_time,
  audience: props.conference.audience,
  registrationEndDate: props.registrationEndDate,
}));

const openModal = () => {

  if (!props.conference.information) {
    Swal.fire({
      icon: "info",
      title: "Información no disponible",
      text: "No hay más información disponible sobre la jornada seleccionada.",
      confirmButtonColor: "#57025b",
    });
    return;
  }

  isModalVisible.value = true;
};

const closeModal = () => {
  isModalVisible.value = false;
};

const handleSubscribe = async () => {
  try {
    if (!concessionaireId.value) {
      throw new Error("No se ha seleccionado un concesionario");
    }

    await ConferenceService.subscribe(
      props.conference.id,
      concessionaireId.value
    );

    Swal.fire({
      icon: "success",
      title: "¡Inscripción exitosa!",
      text: "Te has registrado correctamente a la conferencia",
      confirmButtonColor: props.primaryColor,
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error en suscripción",
      text: error.message || "Ocurrió un error al intentar registrarse",
      confirmButtonColor: props.secondaryColor,
    });
  }
};

</script>

<style scoped>
.evento-card {
  background: #ffffff;
  border-radius: 15px;         
  border: 2px solid #676767;
  color: white;
  display: flex;
  height: 550px;
  margin: 3em 6em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
}

@media (max-width: 992px) {
  .evento-card {
    height: auto;
    flex-direction: column;
    margin: 1rem;
  }

  .evento-card > * {
    flex: 0 0 100% !important;
    width: 100%;
  }

  .evento-image-card {
    order: 0 !important;
  }

  .evento-content-card {
    order: 1 !important;
  }
}
</style>