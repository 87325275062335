import axios from '@/plugins/axios';

export const fetchConcessionaires = async ({ page, perPage, search, type, concessionaireId }) => {
    const url = '/concessionaires'; 
    
    return axios.get(url, { 
      params: {
        page: page,
        perPage: perPage,
        search: search,
        type: type, // 2 = Sucursal, 1 = Casa Central 
        concessionaireId,
      },
      withCredentials: false,
    });
  };

  export const updateConcessionaire = async (concessionaireId, concessionaireData) => {
    try {
      const response = await axios.put(`/concessionaires/${concessionaireId}`, concessionaireData);
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el concesionario:", error);
      throw error;
    }
  };