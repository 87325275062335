<template>
  <div class="card-main">
    <div class="card-header">
      <h3 class="name-evento">{{ name }}</h3>
    </div>

    <div class="card-content">
      <div>
        <div class="info-group">
          <span class="label">HORARIO:</span>
          <span class="value"
            >{{ start_time.slice(0, 5) }} - {{ end_time.slice(0, 5) }} HS</span
          >
        </div>

        <div class="info-group">
          <span class="label">PÚBLICO:</span>
          <span class="value audience-target">{{ audienceTarget }}</span>
        </div>
      </div>

      <div class="acciones">
        <button class="btn-info" @click="onMoreInfoClick">
          MÁS INFORMACIÓN
        </button>
        <button
          class="btn-inscripcion"
          @click="onSubscribeClick"
          v-if="!isRegistrationClosed"
        >
          INSCRIBITE
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
//import { audienceLabels } from "@/utils/audienceLabels";

const emit = defineEmits(["subscribe-click", "more-info-click"]);

const props = defineProps({
  name: String,
  start_time: String,
  end_time: String,
  // audience: Number,
  primaryColor: {
    type: String,
    default: "#57025b",
  },
  audienceTarget: String,
  registrationEndDate: String,
});

/* const audienceLabel = computed(() => {
  return audienceLabels[props.audience] || 'Desconocido';
}); */

const isRegistrationClosed = computed(() => {
  if (!props.registrationEndDate || !props.start_time) return false;

  try {
    const [rYear, rMonth, rDay] = props.registrationEndDate.split("-");
    const [startHours, startMinutes] = props.start_time.slice(0, 5).split(":");

    const deadlineDate = new Date(
      parseInt(rYear),
      parseInt(rMonth) - 1,
      parseInt(rDay),
      parseInt(startHours),
      parseInt(startMinutes)
    );

    const now = new Date();

    return now > deadlineDate;
  } catch (error) {
    console.error("Error al procesar fechas:", error);
    return false;
  }
});

const onSubscribeClick = () => {
  emit("subscribe-click");
};

const onMoreInfoClick = () => {
  emit("more-info-click");
};
</script>

<style scoped>
.card-main {
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card-header {
  background: v-bind(primaryColor);
  border-radius: 0;
}

.evento-content-card[style*="order: 1"] .card-header {
  border-top-right-radius: 11px;
}

.evento-content-card[style*="order: 0"] .card-header {
  border-top-left-radius: 11px;
}

.name-evento {
  font-family: Inter;
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  color: white;
}

.info-group {
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  align-items: center;
}

.label {
  font-size: 22px;
  font-weight: 600;
  text-align: right;
  margin-bottom: 0;
  color: #2f2d2d;
}

.value {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  padding-left: 1rem;
  color: #2f2d2d;
}

.audience-target {
  font-size: 22px;
   word-wrap: break-word; 
  overflow-wrap: break-word; 
  max-height: 100px; 
}

.acciones {
   display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: auto;
  padding-top: 1rem;
  width: 100%;
  margin-bottom: 15px;
  flex-shrink: 0;
}

button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-family: Inter;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
}

.btn-info {
  background: #021f5b;
  color: #ffffff;
}

.btn-inscripcion {
  background: v-bind(primaryColor);
  color: #ffffff;
}

@media (max-width: 1200px) {
  .name-evento {
    font-size: 40px;
  }

  .label {
    font-size: 20px;
  }

  .value {
    font-size: 28px;
  }

  .audience-target {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .name-evento {
    font-size: 36px;
    margin: 1.5rem 0;
  }

  .info-group {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .label {
    text-align: center;
    font-size: 18px;
  }

  .value {
    text-align: center;
    font-size: 22px;
    padding-left: 0;
  }

  .acciones {
    padding-top: 3rem;
    margin-bottom: 30px;
  }

  .audience-target {
    font-size: 18px;
  }

  .card-header {
    border-radius: 0 !important;
  }
}

@media (max-width: 768px) {
  .name-evento {
    font-size: 28px;
  }

  .label {
    font-size: 16px;
  }

  .value {
    font-size: 20px;
  }

  button {
    padding: 0.75rem 1.5rem;
    font-size: 14px;
  }

  .audience-target {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .name-evento {
    font-size: 22px;
  }

  .label {
    font-size: 14px;
  }

  .value {
    font-size: 18px;
  }

  button {
    max-width: 250px;
  }

  .audience-target {
    font-size: 14px;
  }

  .evento-content-card[style*="order: 1"] .card-header {
    border-top-right-radius: 9px;
  }

  .evento-content-card[style*="order: 0"] .card-header {
    border-top-left-radius: 1px;
  }
}
</style>