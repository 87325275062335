<template>
  <div class="container paso-3">
    <div class="row">
      <div class="col-12 text-left mb-5">
        <h3>3. INGRESÁ TUS DATOS</h3>
        <p>
          Completá tus datos, podés elegir más de 1 área y/o cargo. Recordá que
          el administrador de tu concesionario es <strong>{{localFormData.concessionaire_name}}</strong>.
        </p>
        <form>
          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.name"
            placeholder="Nombre"
          />
          <span v-if="errors.name" class="text-danger">{{ errors.name }}</span>

          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.lastname"
            placeholder="Apellido"
            @input="clearError('name')"
          />
          <span v-if="errors.lastname" class="text-danger">{{
            errors.lastname
          }}</span>

          <input
            type="email"
            class="form-control input-custom"
            v-model="localFormData.email"
            placeholder="Email"
            @input="clearError('email')"
          />
          <span v-if="errors.email" class="text-danger">{{
            errors.email
          }}</span>

          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.personal_phone"
            placeholder="Teléfono Fijo"
          />
          <span v-if="errors.personal_phone" class="text-danger">{{
            errors.personal_phone
          }}</span>

          <input
            type="text"
            class="form-control input-custom"
            v-model="localFormData.business_phone"
            placeholder="Teléfono Celular"
            @input="clearError('business_phone')"
          />
          <span v-if="errors.business_phone" class="text-danger">{{
            errors.business_phone
          }}</span>

          <div class="form-group mt-4">
            <label class="form-label text-primary font-weight-bold"
              >Seleccione su área</label
            >
            <multiselect
              v-model="selectedAreas"
              :options="areas"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Seleccione una o más áreas"
              label="name"
              track-by="id"
              class="multiselect-custom"
            />
          </div>

          <div class="form-group mt-4">
            <label class="form-label text-primary font-weight-bold"
              >Seleccione su cargo</label
            >
            <multiselect
              v-model="localFormData.positions"
              :options="filteredPositions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Seleccione uno o más cargos"
              label="name"
              track-by="id"
              class="multiselect-custom"
              @update:modelValue="clearError('positions')"
            >
              <template #option="{ option }">
                <div>
                  <strong>{{ option.area?.name || "Sin área" }}:</strong>
                  {{ option.name }}
                </div>
              </template>

              <template #tag="{ option, remove }">
                <span class="custom-tag">
                  <strong>{{ option.area?.name || "Sin área" }}:</strong>
                  {{ option.name }}
                  <span @click="remove(option)" class="remove">✕</span>
                </span>
              </template>
            </multiselect>
            <span v-if="errors.positions" class="text-danger">
              {{ errors.positions }}
            </span>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button
          class="btn-continuar"
          :disabled="isLoading"
          @click.prevent="nextStep"
        >
          <span v-if="isLoading">
            <i class="fas fa-spinner fa-spin"></i> Procesando...
          </span>
          <span v-else>CONFIRMAR</span>
        </button>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, reactive, onMounted, watch } from "vue";
import Multiselect from "vue-multiselect";
import { fetchPositions } from "@/services/api/position";
import { fetchAreas } from "@/services/api/area";
import useValidations from "@/composables/useValidations";

export default {
  name: "StepThree",
  components: {
    Multiselect,
  },
  props: {
    formData: Object,
    isLoading: Boolean,
  },
  setup(props, { emit }) {
    const localFormData = reactive({ ...props.formData });

    const positions = ref([]);
    const areas = ref([]);

    const selectedAreas = ref([]);

    const filteredPositions = ref([]);

    const { errors, validateFields, clearError } = useValidations();

    const resetFormData = () => {
      localFormData.name = "";
      localFormData.lastname = "";
      localFormData.email = "";
      localFormData.personal_phone = "";
      localFormData.business_phone = "";
      localFormData.positions = [];
    };

    watch(selectedAreas, (newAreas) => {
      filterPositionsByAreas();

      const validAreaIds = newAreas.map((area) => area.id);
      localFormData.positions = localFormData.positions.filter((position) =>
        validAreaIds.includes(position.area_id)
      );
    });

    const fetchAvailableAreas = async () => {
      try {
        const response = await fetchAreas({}, ["ACARA"]);
        areas.value = response.data || [];
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    };

    const fetchAvailablePositions = async () => {
      try {
        const response = await fetchPositions();
        positions.value = response || [];
        filteredPositions.value = [];
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    const filterPositionsByAreas = () => {
      if (selectedAreas.value.length > 0) {
        const selectedAreaIds = selectedAreas.value.map((area) => area.id);
        filteredPositions.value = positions.value.filter((position) =>
          selectedAreaIds.includes(position.area_id)
        );
      } else {
        filteredPositions.value = [];
      }
    };

    const nextStep = () => {
      if (validateFields(localFormData)) {
        const formDataToEmit = {
          ...localFormData,
          positions: localFormData.positions.map((position) => position.id),
        };
        emit("update-form-data", formDataToEmit);
        emit("next");
      }
    };

    onMounted(() => {
      resetFormData();
      fetchAvailableAreas();
      fetchAvailablePositions();
    });

    return {
      selectedAreas,
      localFormData,
      positions,
      areas,
      filteredPositions,
      errors,
      nextStep,
      filterPositionsByAreas,
      clearError,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.paso-3 {
  background: #ffffff;
  padding: 30px 15px;
  text-align: center;
}

h3 {
  color: #00a0e1;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.5rem;
}

p {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 15px;
}

.input-custom {
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  color: #000000;
  padding: 10px 0;
  outline: none;
}

.input-custom::placeholder {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.input-custom:focus {
  border-bottom: 1px solid #00a0e1;
  color: #000;
}

.btn-continuar {
  color: #fff;
  background: #01145b;
  font-size: 1rem;
  font-weight: 800;
  padding: 10px 40px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
}

.multiselect-custom {
  margin-top: 15px;
  font-size: 1rem;
}

.multiselect__tag {
  background: #00a0e1;
  color: white;
  border-radius: 3px;
  padding: 2px 10px;
}

.multiselect__option--highlight {
  background: #01145b;
  color: white;
}

.text-danger {
  font-size: 0.875rem;
  color: #dc3545;
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.3;
}

.validation-error {
  white-space: normal;
  width: 100%;
  text-align: left;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 0.9rem;
  }

  .btn-continuar {
    font-size: 0.9rem;
    padding: 10px 30px;
  }

  .input-custom {
    font-size: 0.9rem;
    padding: 8px 0;
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 0.8rem;
  }

  .btn-continuar {
    font-size: 0.8rem;
    padding: 8px 20px;
  }

  .input-custom {
    font-size: 0.85rem;
    padding: 6px 0;
  }

  .text-danger {
    font-size: 0.75rem;
  }
}
</style>
