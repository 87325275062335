<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNavbar"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      ref="navbar"
    >
      <ul class="navbar-nav mr-auto">
        <NavbarLink
          v-for="link in fixedLinks.initial"
          :key="link.name"
          :name="link.name"
          :to="link.to"
          :active-page="activePage"
          @link-clicked="handleLinkClick"
        />

        <NavbarDropdown
          v-for="dropdown in dropdowns"
          :key="dropdown"
          :name="dropdown"
          :items="getItemsByDropdown(dropdown)"
          :active-page="activePage"
          :open-dropdown="openDropdown"
          @update-active-page="handleLinkClick"
          @dropdown-toggled="handleDropdownToggle"
          @dropdown-item-clicked="closeMenu"
        />

        <NavbarLink
          v-for="link in fixedLinks.final"
          :key="link.name"
          :name="link.name"
          :to="link.to"
          :active-page="activePage"
          @link-clicked="handleLinkClick"
        />

        <NavbarDropdown
          v-if="isAuthenticated && hasConcessionaireAdminRole"
          name="Administrador"
          :items="adminDropdownItems"
          :active-page="activePage"
          :open-dropdown="openDropdown"
          @update-active-page="handleLinkClick"
          @dropdown-toggled="handleDropdownToggle"
          @dropdown-item-clicked="closeMenu"
        />

      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import NavbarLink from "./NavbarLink.vue";
import NavbarDropdown from "./NavbarDropdown.vue";
import { navbarData } from "@/services/api/navbar.js";

export default {
  name: "NavbarComponent",
  components: {
    NavbarLink,
    NavbarDropdown,
  },
  props: {
    activePage: String,
    isMobile: Boolean,
  },
  data() {
    return {
      isNavbarOpen: false,
      fixedLinks: navbarData.fixedLinks,
      dropdowns: ["Divisiones", "Servicios"],
      adminDropdownItems: [
        { name: "Usuarios", to: "/administrador/usuarios" },
        { name: "Solicitudes", to: "/administrador/solicitudes-acceso" },
        { name: "Sucursales", to: "/administrador/sucursales" },
      ],
      allItems: navbarData.items,
      openDropdown: null,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser", "getSelectedConcessionaire"]),
    hasConcessionaireAdminRole() {
      const allowedRoles = [
        "concessionaire-admin",
        "developer",
        "general-admin",
      ];
      return (
        this.getUser?.roles?.some((role) => allowedRoles.includes(role.name)) ||
        false
      );
    },
  },

  methods: {
    getItemsByDropdown(dropdown) {
      return this.allItems.filter((item) => item.dropdown === dropdown);
    },
    handleLinkClick(page) {
      this.$emit("update-active-page", page);
      this.closeMenu();
    },
    handleDropdownToggle(name) {
      if (this.openDropdown === name) {
        this.openDropdown = null;
      } else {
        this.openDropdown = name;
      }
    },
    closeMenu() {
      this.isNavbarOpen = false;
      this.openDropdown = null;
      const navbarCollapse = document.getElementById("navbarSupportedContent");
      if (navbarCollapse?.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      }
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
      const navbarCollapse = document.getElementById("navbarSupportedContent");
      if (navbarCollapse?.classList.contains("show")) {
        navbarCollapse.classList.remove("show");
      } else {
        navbarCollapse?.classList.add("show");
      }
    },
    handleClickOutside(event) {
      const navbar = this.$refs.navbar;
      if (navbar && !navbar.contains(event.target) && this.isNavbarOpen) {
        this.closeMenu();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

.navbar {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0px;
}

.nav-item {
  padding: 0 15px;
  background-color: #f4f4f4;
  margin-right: 3px;
  font-weight: 500;
  font-size: 16px;
}

.nav-item.active {
  color: #ffffff !important;
  background: #001f5b;
}

.nav-item.active a.nav-link {
  color: #ffffff !important;
}

.nav-link {
  color: #02225f !important;
}

.nav-link:hover,
.nav-link:focus {
  background: #001f5b;
  color: #ffffff !important;
}

.nav-item:hover {
  background: #001f5b;
  color: #ffffff !important;
}

.nav-item:hover a.nav-link {
  background: #001f5b;
  color: #ffffff !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #f8f9f9;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
}

.dropdown-item {
  border-bottom: solid 1px #dfe5e7;
  padding: 5px 15px;
  text-align: center;
  color: #001f5b !important;
}

.dropdown-item:hover {
  color: #fff !important;
  background: #06205c;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .navbar-nav {
    flex-direction: column;
    text-align: center;
  }

  .navbar-toggler {
    position: relative;
    top: auto;
    right: auto;
    margin-left: auto;
    z-index: 1050;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(109, 178, 217, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    width: 40px;
    height: 40px;
  }

  .navbar-toggler-icon {
    display: none;
  }

  .navbar-collapse {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding: 20px;
    z-index: 1040;
    width: 100%;
  }

  .nav-item {
    background-color: #ffffff;
    margin: 5px 0;
    padding: 10px 0;
    border-bottom: solid 1px #bfbfbf;
  }

  .nav-item.active {
    color: #302121 !important;
    background: #ffffff;
  }

  .dropdown-menu {
    position: static;
    float: none;
    box-shadow: none;
    padding: 0;
  }
}
</style>
