<template>
  <div v-if="isLoggedIn" class="user-menu">
    <select
      class="concessionaires-select"
      v-if="combinedConcessionaires.length"
      v-model="selectedConcessionaire"
    >
      <option
        v-for="(concessionaire, index) in combinedConcessionaires"
        :key="index"
        :value="concessionaire.business_name"
      >
        {{ concessionaire.business_name }}
      </option>
    </select>
    <span class="user-name">{{ userName }}</span>

    <span class="separator">|</span>

    <button class="logout-btn" @click="handleLogout">Salir</button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "UserMenuComponent",
  props: {
    userName: {
      type: String,
      required: true,
    },
    concessionaires: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const isLoggedIn = computed(() => store.getters.isAuthenticated);

    const combinedConcessionaires = computed(() => {
      return props.concessionaires || [];
    });

    // Inicializar el valor seleccionado con el concesionario principal
    const selectedConcessionaire = computed({
      get: () => store.getters.getSelectedConcessionaire?.business_name || "",
      set: (newValue) => {
        const selected = combinedConcessionaires.value.find(
          (c) => c.business_name === newValue
        );
        if (selected) {
          store.commit("setSelectedConcessionaire", selected);
        }
      },
    });

    const handleLogout = () => {
      store.dispatch("logout");
      sessionStorage.clear();
      router.push({ name: "home" });
    };

    const updateSelectedConcessionaire = () => {
      const selected = combinedConcessionaires.value.find(
        (c) => c.business_name === selectedConcessionaire.value
      );
      if (selected) {
        store.commit("setSelectedConcessionaire", selected);
      }
    };

    watch(selectedConcessionaire, updateSelectedConcessionaire);

    return {
      isLoggedIn,
      combinedConcessionaires,
      selectedConcessionaire,
      handleLogout,
    };
  },
};
</script>


<style scoped>
.user-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.concessionaires-select {
  margin-right: 15px;
  text-align: left;
  text-align-last: left;
  width: 185px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  color: #495057;
}

.user-name {
  font-weight: 800;
  color: #51b6b9;
}

.separator {
  color: #ccc;
  font-weight: 400;
}

.logout-btn {
  background: none;
  border: none;
  color: #51b6b9;
  font-weight: 800;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.logout-btn:hover {
  color: #ff151b;
}
</style>
