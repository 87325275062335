import axios from '@/plugins/axios';

const conventionService = {
    getConventionInformation: async (concessionaireId) => {
        const response = await axios.get('/conventions/information', {
            params: {
                concessionaireId: concessionaireId 
            }
        });
        return response.data;
    }
};

export default conventionService;