<!-- CourseBox.vue -->
<template>
  <router-link
    :to="{
      path: `/cursos/${course.link}`,
      query: { file: course.file },
    }"
    class="box-servicio"
    :class="course.type"
  >
    <div class="img-container">
      <img
        :src="imageMap[course.type]"
        class="img-servicio"
        alt="Imagen del curso"
      />
    </div>
    <img
      :src="iconMap[course.type]"
      class="icono"
      :alt="`Icono ${course.title}`"
    />
    <div class="titulo-y-boton">
      <h3 :class="`color-${course.type}`">
        {{ course.title }}<br />{{ course.level }}
      </h3>
      <p>
        Ingresá para adquirir todos los conocimientos de
        {{ course.title }} {{ course.level }}
      </p>
      <span :class="`bg-${course.type}`">Ingresar</span>
    </div>
  </router-link>
</template>

<script setup>
import { defineProps } from "vue";

// Importación local de imágenes
import cursoExcel from "@/assets/images/capacitacion/curso-excel.jpg";
import cursoPowerpoint from "@/assets/images/capacitacion/curso-powerpoint.jpg";
import cursoWord from "@/assets/images/capacitacion/curso-word.jpg";
import cursoUif from "@/assets/images/capacitacion/curso-uif.jpg";

// Importación local de íconos
import iconExcel from "@/assets/images/capacitacion/icon_curso_excel.png";
import iconPowerpoint from "@/assets/images/capacitacion/icon_curso_powerpoint.png";
import iconWord from "@/assets/images/capacitacion/icon_curso_word.png";
import iconUif from "@/assets/images/capacitacion/logo_uif_web.png";

const imageMap = {
  excel: cursoExcel,
  powerpoint: cursoPowerpoint,
  word: cursoWord,
  uif: cursoUif,
};

const iconMap = {
  excel: iconExcel,
  powerpoint: iconPowerpoint,
  word: iconWord,
  uif: iconUif,
};

defineProps({
  course: {
    type: Object,
    required: true,
  },
});
</script>
<style scoped>
.cursos {
  background-color: #efeff6;
  padding: 60px 0;
}

h1 {
  font-weight: 300;
  color: #06205c;
  text-align: center;
  font-size: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 40px 0;
}

.box-servicio {
  display: block;
  position: relative;
  margin: 50px 0;
  background: #fff;
  text-align: center;
  padding: 0 0 20px 0;
  min-height: 410px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.box-servicio:hover {
  transform: translateY(-5px);
  border: 1px solid #fff;
}

.box-servicio.excel:hover {
  background: #217346;
}
.box-servicio.powerpoint:hover {
  background: #de3711;
}
.box-servicio.word:hover {
  background: #2b579a;
}

.img-container {
  overflow: hidden;
  height: 154px;
  position: relative;
  transition: all 0.3s ease;
}

.img-servicio {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform-origin: center center;
}

.icono {
  position: relative;
  width: 90px;
  margin-top: -60px;
  transition: opacity 0.6s ease 0.2s;
}

.titulo-y-boton {
  padding: 0 20px;
}

h3 {
  font-size: 1.5rem;
  margin: 15px 0;
  transition: color 0.3s ease;
}

.color-excel {
  color: #217346;
}
.color-powerpoint {
  color: #d35230;
}
.color-word {
  color: #2b579a;
}

.box-servicio:hover h3,
.box-servicio:hover p {
  color: white !important;
}

.bg-excel,
.bg-powerpoint,
.bg-word,
.bg-uif {
  display: inline-block;
  padding: 10px 30px;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  margin-top: 15px;
  font-weight: 600;
  transition: filter 0.3s ease;
}

.box-servicio:hover .bg-excel,
.box-servicio:hover .bg-powerpoint,
.box-servicio:hover .bg-word .box-servicio:hover .bg-uif {
  filter: brightness(110%);
}

.bg-excel {
  background-color: #217346;
}
.bg-powerpoint {
  background-color: #d35230;
}
.bg-word {
  background-color: #2b579a;
}

.bg-uif {
  background-color: #2b579a;
}

p {
  color: #757575;
  min-height: 60px;
  margin: 15px 0;
  transition: color 0.3s ease;
}

.img-container {
  position: relative;
  z-index: 2;
  background: white;
}

.box-servicio:hover .img-servicio {
  transform: scale(1.1);
  opacity: 1;
}

.icono {
  position: relative;
  z-index: 5;
  transition: all 0.3s ease;
}

.box-servicio:hover .icono {
  opacity: 0;
}

.box-servicio.excel:hover {
  background: #217346;
}
.box-servicio.powerpoint:hover {
  background: #de3711;
}
.box-servicio.word:hover {
  background: #2b579a;
}

.box-servicio {
  position: relative;
}

.box-servicio:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.img-container {
  z-index: 3;
  background: white;
  position: relative;
}
</style>