<template>
  <div
    class="modal fade"
    id="modalAltaUsuario"
    tabindex="-1"
    role="dialog"
    v-show="isVisible"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="width: 100%">
            <span class="titulo-modal">
              {{ isEditing ? "Editar Usuario" : "Alta de Usuario" }}
            </span>
          </h5>
          <button
            type="button"
            class="close"
            @click="handleClose"
            aria-label="Close"
            style="font-size: 1.5rem; font-weight: bold"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Paso 1 -->
          <div v-if="currentStep === 1">
            <div class="form-group mb-4 text-center">
              <label for="dni">Ingrese el DNI del usuario</label>
              <input
                type="text"
                id="dni"
                class="form-control"
                v-model="localFormData.dni"
                placeholder="Ingrese el DNI"
              />
              <span v-if="errors.dni" class="text-danger d-block mt-2">{{
                errors.dni
              }}</span>
            </div>
          </div>

          <!-- Paso 2 -->
          <div v-if="currentStep === 2">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="dni-bloqueado">DNI</label>
                  <input
                    type="text"
                    id="dni-bloqueado"
                    class="form-control field-blocked"
                    :value="localFormData.dni"
                    readonly
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Nombre</label>
                  <input
                    type="text"
                    id="name"
                    class="form-control"
                    v-model="localFormData.name"
                    @input="clearError('name')"
                  />
                  <span v-if="errors.name" class="text-danger">{{
                    errors.name
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastname">Apellido</label>
                  <input
                    type="text"
                    id="lastname"
                    class="form-control"
                    v-model="localFormData.lastname"
                    @input="clearError('lastname')"
                  />
                  <span v-if="errors.lastname" class="text-danger">{{
                    errors.lastname
                  }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    v-model="localFormData.email"
                    @input="clearError('email')"
                  />
                  <span v-if="errors.email" class="text-danger">{{
                    errors.email
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="personal_phone">Teléfono Fijo</label>
                  <input
                    type="text"
                    id="personal_phone"
                    class="form-control"
                    v-model="localFormData.personal_phone"
                    @input="clearError('personal_phone')"
                  />
                  <span v-if="errors.personal_phone" class="text-danger">{{
                    errors.personal_phone
                  }}</span>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="business_phone">Teléfono Celular</label>
                  <input
                    type="text"
                    id="business_phone"
                    class="form-control"
                    v-model="localFormData.business_phone"
                    @input="clearError('business_phone')"
                  />
                  <span v-if="errors.business_phone" class="text-danger">{{
                    errors.business_phone
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="sucursal">Sucursal</label>
                  <select
                    id="sucursal"
                    class="form-control"
                    v-model="localFormData.concessionaire_id"
                  >
                    <option value="" disabled>Seleccione una Sucursal</option>
                    <option
                      v-for="sucursal in concessionaireOptions"
                      :key="sucursal.id"
                      :value="sucursal.id"
                    >
                      {{ sucursal.cuit }} - {{ sucursal.business_name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Área</label>
                  <multiselect
                    v-model="selectedAreas"
                    :options="areas"
                    :multiple="true"
                    placeholder="Seleccione una o más áreas"
                    label="name"
                    track-by="id"
                    class="multiselect-custom"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cargo</label>
                  <multiselect
                    v-model="localFormData.positions"
                    :options="filteredPositions"
                    :multiple="true"
                    placeholder="Seleccione uno o más cargos"
                    label="name"
                    track-by="id"
                    class="multiselect-custom"
                    @update:modelValue="clearError('positions')"
                  >
                    <template #option="{ option }">
                      <div>
                        <strong>{{ option.area?.name || "Sin área" }}:</strong>
                        {{ option.name }}
                      </div>
                    </template>
                  </multiselect>
                  <span v-if="errors.positions" class="text-danger">
                    {{ errors.positions }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex">
          <button
            v-if="currentStep === 1"
            type="button"
            class="btn btn-primary ms-auto"
            @click="goToNextStep"
          >
            Continuar
          </button>

          <button
            v-if="currentStep === 2 && !isEditing"
            type="button"
            class="btn btn-secondary me-2"
            @click="goToPreviousStep"
          >
            Volver
          </button>

          <button
            v-if="currentStep === 2"
            type="button"
            class="btn btn-primary ms-auto"
            :disabled="isLoading"
            @click="submitForm"
          >
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>{{
              isEditing ? "Guardar Cambios" : "Crear Usuario"
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { checkDni } from "@/services/api/registration";
import Multiselect from "vue-multiselect";
import useValidationsUser from "@/composables/useValidationsUser";
import { fetchPositions } from "@/services/api/position";
import { fetchAreas } from "@/services/api/area";
import { createUser, updateUser } from "@/services/api/user";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "AltaUsuarioModal",
  components: { Multiselect },
  props: {
    isVisible: Boolean,
    isEditing: Boolean,
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentStep = ref(1);
    const isLoading = ref(false);

    const localFormData = reactive({
      name: "",
      lastname: "",
      dni: "",
      email: "",
      personal_phone: "",
      business_phone: "",
      concessionaire_id: null,
      concessionaire_admin: false,
      positions: [],
    });

    const concessionaire = computed(() => store.getters.getConcessionaires);
    
    const concessionaireOptions = computed(() => {
      if (!concessionaire.value || concessionaire.value.length === 0) {
        return [];
      }

      const mainConcessionaire = concessionaire.value[0];
      const sucursals = mainConcessionaire.sucursals || [];

      return [mainConcessionaire, ...sucursals];
    });

    const areas = ref([]);
    const positions = ref([]);
    const filteredPositions = ref([]);
    const selectedAreas = ref([]);
    const { errors, validateFields, clearError } = useValidationsUser();

    const goToNextStep = async () => {
      try {
        const isValidDNI = (dni) => /^\d{7,8}$/.test(dni);

        if (!localFormData.dni || !isValidDNI(localFormData.dni)) {
          errors.dni =
            "El DNI debe ser numérico y tener entre 7 y 8 caracteres.";
          return;
        }

        await checkDni(localFormData.dni);

        clearError("dni");
        currentStep.value = 2;
      } catch (error) {
        console.error("Error al validar el DNI:", error);
        errors.dni =
          error.response?.data?.message ||
          "Hubo un problema al validar el DNI. Intente nuevamente.";
      }
    };

    const goToPreviousStep = () => {
      currentStep.value = 1;
    };

    const handleClose = () => {
      Swal.fire({
        title: "¿Estás seguro de que deseas salir?",
        text: "Los cambios no guardados se perderán.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const modalElement = document.getElementById("modalAltaUsuario");
          if (modalElement) {
            const modalInstance = Modal.getInstance(modalElement);
            modalInstance?.hide();
          }
          emit("close");
        }
      });
    };

    const filterPositionsByAreas = () => {
      if (selectedAreas.value.length > 0) {
        const selectedAreaIds = selectedAreas.value.map((area) => area.id);
        filteredPositions.value = positions.value.filter((position) =>
          selectedAreaIds.includes(position.area_id)
        );
      } else {
        filteredPositions.value = [];
      }
    };

    watch(
      () => props.isEditing,
      async (newValue) => {
        if (newValue) {
          currentStep.value = 2;
          localFormData.name = props.initialData.name || "";
          localFormData.lastname = props.initialData.lastname || "";
          localFormData.dni = props.initialData.dni || "";
          localFormData.email = props.initialData.email || "";
          localFormData.personal_phone = props.initialData.personal_phone || "";
          localFormData.business_phone = props.initialData.business_phone || "";
          localFormData.concessionaire_id =
            props.initialData.concessionaire_id || null;
          localFormData.positions = props.initialData.positions || [];

          const areaIds = props.initialData.positions.map(
            (position) => position.area_id
          );
          selectedAreas.value = areas.value.filter((area) =>
            areaIds.includes(area.id)
          );

          localFormData.concessionaire_admin = props.initialData.roles.some(
            (role) => role.name === "concessionaire-admin"
          );
        } else {
          currentStep.value = 1;
          localFormData.name = "";
          localFormData.lastname = "";
          localFormData.dni = "";
          localFormData.email = "";
          localFormData.personal_phone = "";
          localFormData.business_phone = "";
          localFormData.concessionaire_id = null;
          localFormData.positions = [];
          selectedAreas.value = [];

          localFormData.concessionaire_admin = false;
        }
      },
      { immediate: true }
    );

    watch(selectedAreas, (newAreas) => {
      filterPositionsByAreas();

      const validAreaIds = newAreas.map((area) => area.id);
      localFormData.positions = localFormData.positions.filter((position) =>
        validAreaIds.includes(position.area_id)
      );
    });

    watch(areas, (newAreas) => {
      if (props.isEditing) {
        const areaIds = props.initialData.positions.map(
          (position) => position.area_id
        );
        selectedAreas.value = newAreas.filter((area) =>
          areaIds.includes(area.id)
        );
      }
    });

    watch(
      () => concessionaire.value,
      (newValue) => {
        if (newValue && newValue.length > 0) {
          localFormData.concessionaire_id = newValue[0].id;
        }
      },
      { immediate: true }
    );

    const loadAreasAndPositions = async () => {
      try {
         const response = await fetchAreas({}, ["ACARA"]);
        areas.value = response.data || [];
        const fetchedPositions = await fetchPositions();
        positions.value = fetchedPositions || [];
        filterPositionsByAreas();
      } catch (error) {
        console.error("Error al cargar áreas y posiciones:", error);
      }
    };

    const submitForm = async () => {
      if (!validateFields(localFormData)) return;
      isLoading.value = true;
      try {
        if (props.isEditing) {
          await updateUser(props.initialData.id, {
            ...localFormData,
            positions: localFormData.positions.map((pos) => pos.id),
          });
          const successMessage = "Usuario actualizado correctamente.";
          emit("success", successMessage);
        } else {
          await createUser({
            ...localFormData,
            positions: localFormData.positions.map((pos) => pos.id),
          });
          const successMessage = "Usuario creado correctamente.";
          emit("success", successMessage);
        }

         emit("close");
      } catch (error) {
        handleError(
          error,
          `Error al ${props.isEditing ? "actualizar" : "crear"} usuario`
        );
      } finally {
        isLoading.value = false;
      }
    };

    const handleError = (error, title) => {
      console.error(title, error);
      const errorMessage = error.response?.data?.message || "Error inesperado";
      Swal.fire({
        icon: "error",
        title,
        text: errorMessage,
      });
    };

    onMounted(loadAreasAndPositions);

    return {
      isLoading,
      concessionaireOptions,
      concessionaire,
      currentStep,
      goToNextStep,
      goToPreviousStep,
      handleClose,
      localFormData,
      areas,
      filteredPositions,
      filterPositionsByAreas,
      selectedAreas,
      submitForm,
      clearError,
      errors,
    };
  },
};
</script>


<style scoped>
.modal-dialog {
  max-width: 900px;
  margin: auto;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  background-color: #007bff;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 20px;
  text-align: center;
}

.modal-header .modal-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 auto;
}

.modal-header .btn-close,
.modal-header .close {
  color: white;
  font-size: 1.25rem;
  background-color: transparent;
  border: none;
  opacity: 0.8;
  cursor: pointer;
}

.modal-header .btn-close:hover,
.modal-header .close:hover {
  opacity: 1;
}

.custom-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #007bff;
  color: white;
  padding: 15px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-modal-header .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.custom-modal-header .btn-close {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.custom-modal-header .btn-close:hover {
  color: #ffcccc;
}

.modal-body {
  padding: 30px;
  background-color: #f9f9f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
}

.row + .row {
  margin-top: 1rem;
}

.field-blocked {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.field-blocked:focus {
  background-color: #e9ecef;
  outline: none;
}

.modal-footer .btn {
  border-radius: 5px;
  padding: 10px 20px;
}

.modal-footer .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.modal-footer .btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.modal-footer .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: white;
}

.modal-footer .btn-secondary:hover {
  background-color: #5a6268;
  border-color: #5a6268;
}

.form-group label {
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
  color: #333;
}

.spinner-border {
  margin-right: 5px;
}
</style>

