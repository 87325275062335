import axios from '@/plugins/axios';

export const fetchProvinces = async (params) => {
    try {
        const response = await axios.get('/provinces', {
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};

  // Obtener localidades de todas las provincias
export const fetchAllLocalities = async () => {
    try {
        const response = await axios.get('/provinces/localities');
        return response.data;
    } catch (error) {
        console.error('Error fetching all localities:', error);
        throw error;
    }
  };


export const fetchProvinceRegionals = async (provinceId) => {
  try {
      const response = await axios.get(`/provinces/${provinceId}/regionals`);
      return response.data;
  } catch (error) {
      console.error('Error fetching province regionals:', error);
      throw error;
  }
};

// Obtener localidades asociadas a una provincia
export const fetchProvinceLocalities = async (provinceId) => {
  try {
      const response = await axios.get(`/provinces/${provinceId}/localities`);
      return response.data;
  } catch (error) {
      console.error('Error fetching province localities:', error);
      throw error;
  }
};


