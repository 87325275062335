import { reactive } from 'vue';

export const useFormValidator = (formData, formErrors) => {

  const validations = reactive({
    requiredFields: [],
    customValidators: {}
  });

  const setValidations = (requiredFields, customValidators = {}) => {
    validations.requiredFields = requiredFields;
    validations.customValidators = customValidators;
  };

  const validate = () => {
    let isValid = true;
    Object.keys(formErrors).forEach(key => delete formErrors[key]);
    validations.requiredFields.forEach(field => {
      if (!formData[field] || formData[field]?.length === 0) {
        formErrors[field] = "Este campo es requerido";
        isValid = false;
      }
    });

    Object.entries(validations.customValidators).forEach(([field, validator]) => {
      const error = validator(formData[field]);
      if (error) {
        formErrors[field] = error;
        isValid = false;
      }
    });
    return isValid;
  };

  return {
    setValidations,
    validate
  };
};