<template>
  <section class="presentacion">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <img src="@/assets/images/icon_nuestra_vision.png" height="120" alt="Nuestra Visión" />
        </div>
        <div class="col-12 col-md-8 text-left">
          <p class="title">Nosotros - Nuestra Visión</p>
          <p>El capital humano es el atributo diferenciador de todos los negocios, la función de los mismos,
            sin abandonar lo operativo, se torna cada vez más en un eje estratégico de la organización.
          </p>
        </div>
      </div>
      <div class="separador"></div>
      <div class="row">
        <div class="col-12 col-md-8 text-left">
          <p class="title">Quiénes Somos</p>
          <p>Somos el recurso estratégico que interactúa con todos los asociados de ACARA, desarrollando Programas de Capacitación y Formación para todas las áreas de la empresa.
          </p>
        </div>
        <div class="col-12 col-md-4 text-center">
          <img src="@/assets/images/icon_quienes_somos.png" height="120" alt="Quiénes Somos" />
        </div>
      </div>
      <div class="separador"></div>
      <div class="row">
        <div class="col-12 col-md-12 text-left">
          <p class="title">Nuestro Diferencial</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 text-left">
          <p>Nos caracterizamos por entender la temática del mundo de las concesionarias automotor, motovehículo y maquinaria agrícola como una realidad específica que requiere de nuestro profundo conocimiento e involucramiento aportando, brindando, poniendo a disposición nuestros saberes, instrumentos y experiencias.</p>
          <p>Trabajamos de manera interactiva en la clarificación y detección de necesidades, conflictos, diseño e implementación de las respuestas más adecuadas, con claro foco en la obtención de resultados.</p>
          <p>Somos altamente innovadores tanto en nuestros servicios como en nuestra modalidad de actuación profesional. Abordamos las Metodologías o combinación de vanguardia por su carácter integral, su rapidez, efectividad con búsqueda de resultados superadores.</p>
          <p>Nuestro abordaje integrador unifica criterios, recursos, teorías y técnicas de diferentes fuentes de saber y conocimiento, con la finalidad de promover la integración del ser humano y lograr de esta manera su realización personal y profesional, laboral y social.</p>
          <p>"Nuestro equipo lo acompaña a producir el cambio organizacional siendo el cambio mismo"</p>
        </div>
      </div>
    </div>
  </section>
  <CursosOfficeComponent v-if="isAuthenticated" />
</template>

<script setup>
import { onMounted, computed } from "vue";
import CursosOfficeComponent from './CursosOfficeComponent.vue';
import { useStore } from "vuex";

const store = useStore();
const isAuthenticated = computed(() => !!store.state.user);
onMounted(() => {
 
});
</script>

<style scoped>
.presentacion {
    margin-top: 40px;
  padding: 20px 0;
}

.presentacion p.title {
  color: #00a0e1;
  font-weight: 800;
  text-transform: uppercase;
}

.presentacion p {
  color: #757575;
}

.separador {
  margin: 20px 0;
  border-bottom: 1px solid #ddd;
}
</style>
