import axios from "@/plugins/axios";

export const fetchBrandList = async (vehicleType) => {
  try {
    const response = await axios.get(`/prices/brand-list`, {
      params: { vehiculeType: vehicleType },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error al obtener lista de marcas:", error);
    throw error;
  }
};


export const fetchAllBrands = async () => {
  try {
    const response = await axios.get(`/prices/all-brands`);
    return response.data.data;
  } catch (error) {
    console.error("Error al obtener todas las marcas:", error);
    throw error;
  }
};

export const fetchBrandsByVehiculeType = async (vehicleType) => {
  try {
    const response = await axios.get(`/prices/brands-by-vehicule-type`, {
      params: { vehiculeType: vehicleType },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error al obtener marcas por tipo de vehículo:", error);
    throw error;
  }
};