import axios from '@/plugins/axios';

export const fetchAreas = async (params = {}, excludeNames = []) => {
  try {
    const response = await axios.get('/areas', { params });
    
    const originalData = response.data.data || [];
    
    const filteredData = originalData.filter(
      area => !excludeNames.includes(area.name.toUpperCase())
    );
    
    return {
      ...response.data,
      data: filteredData
    };
    
  } catch (error) {
    console.error('Error fetching areas:', error);
    throw error;
  }
};