export const footer = {
  contact: {
    address: "Av Córdoba 3371, CABA",
    phone: '(011) 5236 6500',
    email: 'socios@acara.org.ar'
  },
  social: {
    instagram: "https://www.instagram.com/acaraoficial/?hl=es-la",
    facebook: "https://www.facebook.com/Acara-Oficial-100506451482577/",
    twitter: "https://twitter.com/acaraoficial",
     linkedin: "https://www.linkedin.com/company/acara-org/?viewAsMember=true"
  },
  legal: {
    copyright: "ACARA - Todos los derechos reservados.",
  },
  status: 2
};