<template>
  <div :class="className">
    <div class="contact-item-container d-flex justify-content-center w-100">
      <img 
        :src="getImage(item.icon)" 
        :alt="item.title" 
        class="contact-image"
      />
      <div class="contact-text-wrapper">
        <p class="contact-text mb-0">
          <strong>{{ item.title }}</strong><br />
          <a v-if="item.link" :href="item.link" style="color: #fff;">
            {{ item.value }}
          </a>
          <template v-else>{{ item.value }}</template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getImage } from '@/utils/mediaUtils.js';

export default {
  name: "ContactItem",
  props: {
    item: Object,
    className: String
  },
  methods: {
    getImage
  }
};
</script>

<style scoped>
.contact-item-container {
  align-items: flex-start;
}

.contact-image {
  height: 40px;
  flex-shrink: 0;
  margin-right: 15px; 
}

.contact-text-wrapper {
  display: inline-flex; 
}

.contact-text {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 100;
  margin-top: 6px;
  display: inline-block; 
  white-space: nowrap;
}

@media (max-width: 576px) {
  .contact-item-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .contact-image {
    margin-bottom: 10px;
    margin-right: 0;
  }
  
  .contact-text-wrapper {
    text-align: center;
    display: block;
  }
  
  .contact-text {
    white-space: normal;
  }
}
</style>