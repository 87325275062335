<template>
  <section class="telepase">
    <div class="container h-100 d-flex justify-content-center align-items-end">
      <div class="row">
        <div class="botones">
          <button
            class="btn btn-light"
            @click="handleAction('https://telepase.com.ar/register')"
          >
            Registrar Cliente en AUSA
          </button>
          <button
            class="btn btn-light"
            @click="handleAction('https://telepase.com.ar/login')"
          >
            Asociar Pase al Dominio
          </button>
          <a
            v-if="isAuthenticated"
            href="https://www.acara.org.ar/files/telepase/Video%20Telepase.mp4"
            target="_blank"
          >
            <button class="btn btn-light">Ver Video</button>
          </a>
          <a
            v-if="isAuthenticated"
            href="https://www.acara.org.ar/files/telepase/ACARA%20Telepase.pdf"
            target="_blank"
          >
            <button class="btn btn-light">Ver Instructivo</button>
          </a>
        </div>
      </div>
    </div>
    <LoginModalComponent />
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import LoginModalComponent from "@/components/header/login/LoginModalComponent.vue";
import * as bootstrap from "bootstrap";

const store = useStore();
const isAuthenticated = computed(() => !!store.state.user);

const handleAction = (url) => {
  if (isAuthenticated.value) {
    window.open(url, "_blank");
  } else {
    const modalElement = document.getElementById("ingresarModal");
    if (modalElement) {
      const loginModal = new bootstrap.Modal(modalElement);
      loginModal.show();
    } else {
      console.error("No se encontró el elemento modal.");
    }
  }
};
</script>

<style scoped>
.telepase {
  background: url('@/assets/images/telepase-acara-ausa.jpg') no-repeat center;
  background-size: cover;
  background-color: #435b39;
  min-height: 100vh;  
  width: 100%;       
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative; 
}

.container {
  height: 100%;
  padding: 0;
}

.botones {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  margin-bottom: 123px;
}

.btn {
  color: white !important;
  font-weight: 800;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #debd30 !important;
  border-radius: 8px;
  border: none;
  padding: 12px 20px;
  transition: all 0.3s ease;
  width: 280px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  filter: brightness(0.9);
}

@media (max-width: 768px) {
  .telepase {
    height: 400px;
  }

  .botones {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 100px;
    padding: 0 20px;
  }

  .btn {
    width: 100% !important;
    
    height: 40px !important;
    font-size: 10px !important;
    padding: 5px 10px !important;
    border-radius: 6px;
    max-width: none; 
    
    flex: none;
    
  }
}

@media (max-width: 1440px) {
  .telepase {
    min-height: 135vh;
  }


}
</style>
