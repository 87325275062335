<template>
  <div class="modal-overlay-custom" @click.self="emit('close')">
    <div class="modal-custom">
      <div class="modal-header-custom">
        <h3>{{ modalTitle }}</h3>
        <button class="close-btn-custom" @click="emit('close')">×</button>
      </div>
      <div class="modal-body-custom">
        <ul class="file-list-custom">
          <li
            v-for="(file, index) in buttonFiles"
            :key="index"
            class="file-item-custom"
          >
            <i class="file-icon-custom fas fa-file-pdf"></i>
            <span class="file-name-custom">{{ file.name }}:</span>
            <a :href="getFileUrl(file.file)" target="_blank" class="file-link-custom">Acceder</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import config from "@/config";

defineProps({
  buttonFiles: {
    type: Array,
    required: true,
    default: () => [] 
  },
  modalTitle: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const getFileUrl = (filePath) => {
  return `${config.backendUrl.replace(/\/+$/, '')}/${filePath.replace(/^\/+/, '')}`;
};
</script>



<style scoped>
.modal-overlay-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.modal-custom {
  background: #fff;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  padding: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 3100;
}

.modal-header-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 3200;
}

.modal-header-custom h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.close-btn-custom {
  background: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  padding: 0;
}

.close-btn-custom:hover {
  color: #e0e0e0;
}

.modal-body-custom {
  padding: 20px;
}

.file-list-custom {
  list-style: none;
  padding: 0;
  margin: 0;
}

.file-item-custom {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.file-icon-custom {
  color: #d9534f;
  font-size: 20px;
  margin-right: 10px;
}

.file-name-custom {
  font-weight: bold;
  margin-right: 10px;
}

.file-link-custom {
  color: #007bff;
  text-decoration: none;
}

.file-link-custom:hover {
  text-decoration: underline;
}
</style>
