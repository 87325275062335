import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import { validateToken } from "@/services/api/authService";
import { setAuthToken, clearAuthToken } from "@/helpers/authManager";
import { fetchAllViews } from "@/services/api/view";

const vuexPersist = new VuexPersist({
  key: "app_state",
  storage: window.localStorage,
  reducer: (state) => ({
    user: state.user,
    selectedConcessionaire: state.selectedConcessionaire,
    isLoggedIn: state.isLoggedIn,
    views: state.views,
  }),
});

export default createStore({
  state: {
    user: null,
    isLoggedIn: false,
    selectedConcessionaire: null,
    views: [],
    loginRedirect: null,
  },
  mutations: {
    setLoginRedirect(state, path) {
      state.loginRedirect = path;
    },
    clearLoginRedirect(state) {
      state.loginRedirect = null;
    },
    setUser(state, userData) {
      state.user = userData;
      state.isLoggedIn = !!userData.token;

      if (userData.token) {
        setAuthToken(userData.token);
      } else {
        clearAuthToken();
      }

      if (state.selectedConcessionaire === null) {
        if (userData.concessionaires?.length > 0) {
          state.selectedConcessionaire = userData.concessionaires[0];
        } else {
          state.selectedConcessionaire = null;
        }
      }
    },
    clearUser(state) {
      state.user = null;
      state.isLoggedIn = false;
      state.selectedConcessionaire = null;
      clearAuthToken();
    },
    setSelectedConcessionaire(state, concessionaire) {
      state.selectedConcessionaire = concessionaire;
    },
    setViews(state, views) {
      state.views = views;
    },
    resetViews(state) {
      state.views = [];
    },
  },
  actions: {

    async validateTokenAction({ commit }) {
      const isValid = await validateToken();
      if (!isValid) {
        commit("clearUser");
      }
      return isValid;
    },
    async fetchViews({ commit }) {
      commit("resetViews");
      try {
        const allViews = await fetchAllViews();
        const filteredViews = allViews.filter((view) => view.url === "divisiones-autos");
        commit("setViews", filteredViews);
        return filteredViews;
      } catch (error) {
        console.error("Error al cargar las vistas dinámicas:", error);
        throw error;
      }
    },
    login({ commit }, userData) {
      commit("setUser", userData);
    },
    logout({ commit }) {
      commit("clearUser");

      const backdrops = document.querySelectorAll(".modal-backdrop");
      backdrops.forEach((backdrop) => backdrop.remove());
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn;
    },
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.user?.token || null;
    },
    getRoles(state) {
      return state.user?.roles || [];
    },
    getConcessionaires(state) {
      return state.user?.concessionaires || [];
    },
    getSelectedConcessionaire(state) {
      return state.selectedConcessionaire;
    },
    getViews(state) {
      return state.views;
    },
  },
  plugins: [vuexPersist.plugin],
});