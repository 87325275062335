import axios from "@/plugins/axios";

export async function fetchUserViews(userId) {
    try {
        const response = await axios.get(`/concessionaire-users/${userId}/views`);
        return response.data.data || [];
    } catch (error) {
        console.error("Error al obtener las vistas del usuario:", error);
        throw error;
    }
}

export async function syncUserViews(userId, views) {
    try {
        const response = await axios.post(`/concessionaire-users/${userId}/sync-views`, {
            views,
        });
        return response.data;
    } catch (error) {
        console.error("Error al sincronizar las vistas del usuario:", error);
        throw error;
    }
}

export async function syncUserRoles(userId, roles) {
    try {
        const response = await axios.post(
            `/concessionaire-users/${userId}/sync-roles`,
            { roles }
        );
        return response.data;
    } catch (error) {
        console.error("Error al sincronizar roles del usuario:", error);
        throw error;
    }
}

export async function fetchUserRoles(userId) {
    try {
        const response = await axios.get(`/concessionaire-users/${userId}/roles`);
        return response.data.data || [];
    } catch (error) {
        console.error("Error al obtener los roles del usuario:", error);
        throw error;
    }
}