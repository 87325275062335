<template>
  <section class="sucursales">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <swiper-container :slides-per-view="1" :loop="true" :autoplay="{ delay: 3000, disableOnInteraction: false }"
            :speed="1000" class="sucursalSwiper">
            <swiper-slide v-for="slide in sucursalSlides" :key="slide.id" class="text-center">
              <div class="image-group">
                <div v-for="(imageGroup, index) in groupImages(slide.images)" :key="index" class="image-row">
                  <img v-for="(image, imgIndex) in imageGroup" :key="imgIndex" :src="getImage(image.src, 'regional')"
                    :alt="image.alt" class="sucursal-media" />
                </div>
              </div>
            </swiper-slide>
          </swiper-container>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { sucursalSlides } from "@/services/api/sucursal.js";
import { getImage } from "@/utils/mediaUtils.js";
import "swiper/css";

export default defineComponent({
  name: "SucursalComponent",
  setup() {
    const slides = ref(sucursalSlides);
    const isMobile = computed(() => window.innerWidth <= 768);

    const groupImages = (images) => {
      const groupSize = isMobile.value ? 2 : 4;
      const grouped = [];
      for (let i = 0; i < images.length; i += groupSize) {
        grouped.push(images.slice(i, i + groupSize));
      }
      return grouped;
    };

    return {
      sucursalSlides: slides,
      getImage,
      groupImages,
    };
  },
});
</script>

<style scoped>
.sucursales {
  padding: 18px 0;
}

.image-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  flex-wrap: wrap;
}

.image-row {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.sucursal-media {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 10px;
  display: block;
}

@media (max-width: 768px) {
  .sucursal-media {
    width: 120px;
    height: 120px;
  }

  .image-row {
    gap: 10px;
  }
}

@media (max-width: 576px) {
  .sucursal-media {
    width: 100px;
    height: 100px;
  }

  .image-group {
    gap: 10px;
  }
}

.sucursalSwiper {
  width: 100%;
  height: auto;
  position: relative;
}
</style>