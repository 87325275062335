<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">
        <span class="close-icon">×</span>
      </button>
      <div class="modal-header">
        <div class="image-container">
          <img 
            :src="imageUrl"
            :alt="name" 
            class="event-image"
          />
        </div>
        <div class="details-container">
          <h2>{{ name }}</h2>
          <p class="event-time">
            ({{ start_time ? start_time.slice(0, 5) : '--:--' }} hs.)
          </p>
        </div>
      </div>
      <div class="modal-scrollable-body">
        <div class="event-description">
          <p v-html="formattedDescription"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import config from "@/config";

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  start_time: {
    type: String,
    default: "00:00:00",
  },
  description: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const imageUrl = computed(() => {
  return `${config.backendUrl}/${props.image}`;
});

const formattedDescription = computed(() => {
  return props.description
    ? `<span class="orange-arrow">>></span> ${props.description.replace(/\n/g, "<br>")}`
    : "";
});

const closeModal = () => {
  emit('close');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
    backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 800px;
  width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.modal-body {
  text-align: left;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
    flex-shrink: 0; 
}


.modal-scrollable-body {
  flex: 1; 
  overflow-y: auto;
  padding-right: 10px;
}

.modal-scrollable-body::-webkit-scrollbar {
  width: 8px;
}

.modal-scrollable-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-scrollable-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-scrollable-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.image-container {
  width: 25%;
  max-width: 150px;
  margin-right: 20px;
}

.event-image {
  padding: 12px;
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 4px solid #007bff;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.details-container {
  width: 75%;
}

h2 {
    color: #003366;
  font-size: 38px;
  font-weight: bold;
}

.event-time {
   color: #003366;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.event-description {
  color: #003366;
  font-size: 24px; 
  font-weight: 900;
  margin-right: 8px;
}

.event-description p {
  margin: 0;
}

.event-description :deep(.orange-arrow) {
  color: #FF6F00;
  font-size: 24px;
  font-weight: 900;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: 2px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.close-button:hover {
  background: #f8f8f8;
  transform: scale(1.1);
  border-color: #007bff;
}

.close-icon {
  font-size: 28px;
  line-height: 1;
  color: #333;
  margin-top: -2px;
  transition: color 0.3s ease;
}

.close-button:hover .close-icon {
  color: #007bff;
}

@media (max-width: 768px) {
     .close-button {
    top: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
  }
  
  .close-icon {
    font-size: 24px;
  }
  .modal-content {
    max-width: 90%;
    padding: 20px;
  }

  .image-container {
    width: 30%;
    max-width: 120px;
  }

 h2 {
    font-size: 28px;
  }

  .event-time {
    font-size: 16px;
  }

  .event-description :deep(.orange-arrow) {
    font-size: 20px;
  }

  .orange-arrow {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
     .close-button {
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }
  
  .close-icon {
    font-size: 20px;
  }
  .modal-content {
    max-width: 95%;
    padding: 15px;
  }

  .image-container {
    width: 35%;
    max-width: 100px;
  }

  h2 {
    font-size: 24px;
  }

  .event-time {
    font-size: 16px;
  }

  .event-description {
    font-size: 14px;
  }

  .event-description :deep(.orange-arrow) {
    font-size: 18px;
  }
}
</style>