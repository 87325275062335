import axios from '@/plugins/axios';

export default {
  async subscribe(conferenceId, concessionaireId) {
    try {
      const response = await axios.post(
        `/conferences/${conferenceId}/subscribe`,
        {
          concessionaireId: concessionaireId
        }
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message || 'Error al suscribirse');
      }
      throw new Error('Error de conexión');
    }
  }
}