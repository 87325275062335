<template>
  <div
    v-if="isOpen"
    class="modal fade show d-block"
    tabindex="-1"
    aria-labelledby="circularsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="circularsModalLabel" class="modal-title">
            Temáticas asignadas a {{ user?.name || "Usuario" }}
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Cerrar"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading" class="loading">
            <p>Cargando temáticas...</p>
          </div>
          <div v-else>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th
                    class="text-center"
                    style="vertical-align: middle; position: relative"
                  >
                    <label
                      class="form-check-label d-flex align-items-center justify-content-center"
                    >
                      <input
                        type="checkbox"
                        :checked="allSelected"
                        @change="toggleAll"
                        class="form-check-input me-2"
                        aria-label="Seleccionar o deseleccionar todas las temáticas"
                      />
                      <span class="d-inline-flex align-items-center">
                        <i
                          class="bi bi-check2-square me-1"
                          aria-hidden="true"
                        ></i>
                        Activar Todos
                      </span>
                    </label>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="topic in topics" :key="topic.id">
                  <td>{{ topic.description || "Sin nombre" }}</td>
                  <td class="text-center">
                    <input
                      type="checkbox"
                      :checked="isTopicSelected(topic.id)"
                      @change="toggleTopic(topic.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="topics.length === 0" class="no-results">
              <p class="text-center text-muted">
                No se encontraron temáticas asignadas.
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="saveTopics"
            :disabled="isLoading"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineEmits, defineProps } from "vue";
import Swal from "sweetalert2";
import { fetchCircularRoles } from "@/services/api/circulares";
import {
  syncUserRoles,
  fetchUserRoles,
} from "@/services/api/concessionaire-user";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const topics = ref([]);
const selectedTopics = ref([]);
const isLoading = ref(false);

const allSelected = computed(() => {
  return (
    topics.value.length > 0 &&
    topics.value.every((topic) => selectedTopics.value.includes(topic.id))
  );
});

const isTopicSelected = (id) => {
  return selectedTopics.value.includes(id);
};

const toggleAll = () => {
  if (allSelected.value) {
    selectedTopics.value = [];
  } else {
    selectedTopics.value = topics.value.map((topic) => topic.id);
  }
};

const toggleTopic = (id) => {
  const index = selectedTopics.value.indexOf(id);
  if (index > -1) {
    selectedTopics.value.splice(index, 1);
  } else {
    selectedTopics.value.push(id);
  }
};

const saveTopics = async () => {
  try {
    isLoading.value = true;

    await syncUserRoles(props.user.id, selectedTopics.value);

    const successMessage = "Las temáticas se han guardado correctamente.";
    emit("success", successMessage);

    emit("close");
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text:
        error.response?.data?.message || "No se pudieron guardar las temáticas",
    });
  } finally {
    isLoading.value = false;
  }
};

const loadCirculars = async () => {
  isLoading.value = true;
  try {
    const userRoles = await fetchUserRoles(props.user.id);
    const allRoles = await fetchCircularRoles();
    topics.value = allRoles.data;
    selectedTopics.value = userRoles.map((role) => role.id);
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "No se pudieron cargar las temáticas.",
    });
  } finally {
    isLoading.value = false;
  }
};

const closeModal = () => {
  emit("close");
};

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) loadCirculars();
  }
);
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 700px;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: #007bff;
  color: #fff;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 1rem 1.5rem;
}

.modal-header .btn-close {
  color: #fff;
}

.modal-header .btn-close:hover {
  color: #d9d9d9;
}

.modal-body {
  padding: 1.5rem;
}

.table {
  margin-bottom: 0;
}

.table th {
  background-color: #f8f9fa;
}

.no-results {
  padding: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem;
  background-color: #f8f9fa;
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

td {
  vertical-align: middle;
}
</style>