<template>
  <section class="contacto">
    <div class="container">
      <div class="row justify-content-between">
        <ContactItem
          v-for="(item, key) in contactItems"
          :key="key"
          :item="item"
          class="col-12 col-md-4 mb-4 mb-md-0 d-flex justify-content-center"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { getImage } from '@/utils/mediaUtils.js';
import ContactItem from '@/components/footer/ContactItem.vue';

export default {
  name: "ContactoSection",
  components: { ContactItem },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactItems() {
      return {
        phone: {
          icon: 'icon_footer_phone.png',
          title: 'Contactate con nosotros',
          value: this.contact.phone,
          link: `tel:${this.contact.phone}`
        },
        email: {
          icon: 'icon_footer_envelope.png',
          title: 'Envianos tus consultas a',
          value: this.contact.email,
          link: `mailto:${this.contact.email}`
        },
        address: {
          icon: 'icon_footer_map.png',
          title: 'Encontranos',
          value: 'estés dónde estés'
        }
      };
    },
  },
  methods: {
    getImage
  }
};
</script>

<style scoped>
* {
  font-family: 'Inter', sans-serif;
}

.contacto {
  background: #0177bd;
  color: #fff;
  padding: 40px 0 34px 0;
}

.contacto img {
  height: 50px;
  vertical-align: top;
  font-weight: 100;
  margin-right: 15px;
}

.contacto p {
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 100;
  position: relative;
  margin-top: 6px;
  text-align: left;
}

.contact-image {
  height: 40px;
}

.contact-text {
  font-size: 0.8rem;
}

@media (max-width: 576px) {
  .contacto img {
    height: 40px;
  }

  .contacto p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
</style>