<template>
  <section class="cursos" id="cursos">
    <div class="container">
      <!-- Sección Cursos Office -->
      <div class="row">
        <div class="col-12">
          <h1>Cursos Office</h1>
        </div>
      </div>

      <!-- Fila 1 - Excel y PowerPoint -->
      <div class="row justify-content-center">
        <div
          v-for="(course, index) in officeCourses.row1"
          :key="index"
          class="col-12 col-md-3"
        >
          <CourseBox :course="course" />
        </div>
      </div>

      <!-- Fila 2 - Word -->
      <div class="row justify-content-center">
        <div
          v-for="(course, index) in officeCourses.row2"
          :key="index"
          class="col-12 col-md-3"
        >
          <CourseBox :course="course" />
        </div>
      </div>

      <!-- Sección Cursos UIF -->
      <!-- <div class="row">
        <div class="col-12">
          <h1>Cursos UIF</h1>
        </div>
      </div> -->

      <!-- Fila UIF -->
<!--       <div class="row justify-content-center">
        <div
          v-for="(course, index) in uifCourses"
          :key="index"
          class="col-12 col-md-3"
        >
          <CourseBox :course="course" />
        </div>
      </div> -->
    </div>
  </section>
</template>

<script setup>
import CourseBox from "./CourseBox.vue";



const officeCourses = {
  row1: [
    {
      title: "Excel",
      level: "Inicial",
      link: "excel-inicial",
      type: "excel",
      file: "cursos-excel-inicial.html",
    },
    {
      title: "Excel",
      level: "Intermedio",
      link: "cursos-excel-intermedio",
      type: "excel",
       file: "cursos-excel-intermedio.html",
    },
    {
      title: "Excel",
      level: "Avanzado",
      link: "cursos-excel-avanzado",
      type: "excel",
       file: "cursos-excel-avanzado.html",
    },
    {
      title: "PowerPoint",
      level: "Integral",
      link: "cursos-powerpoint",
      type: "powerpoint",
       file: "cursos-powerpoint.html",
    },
  ],
  row2: [
    {
      title: "Word",
      level: "Inicial",
      link: "cursos-word-inicial",
      type: "word",
       file: "cursos-word-inicial.html",
    },
    {
      title: "Word",
      level: "Intermedio",
      link: "cursos-word-intermedio",
      type: "word",
       file: "cursos-word-intermedio.html",
    },
    {
      title: "Word",
      level: "Avanzado",
      link: "cursos-word-avanzado",
      type: "word",
       file: "cursos-word-avanzado.html",
    },
  ]
};

/* const uifCourses = ref([
  {
    title: "UIF",
    level: "2024",
    link: "cursos-uif-2024",
    type: "uif",
     file: "cursos-excel-inicial.html",
  },
]); */

</script>

<style scoped>
.cursos {
  background-color: #efeff6;
  padding: 60px 0;
}

h1 {
  font-weight: 300;
  color: #06205c;
  text-align: center;
  font-size: 2.3rem;
  text-transform: uppercase;
  margin: 0 0 40px 0;
}

/* Mantenemos los estilos existentes y agregamos los nuevos para UIF */
.box-servicio.uif:hover {
  background: #4a148c;
}

.color-uif {
  color: #4a148c;
}

.bg-uif {
  background-color: #4a148c;
}
</style>