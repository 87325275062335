import { ref } from "vue";

export default function usePagination(fetchFunction) {
  const currentPage = ref(1);
  const lastPage = ref(1);
  const hasMorePages = ref(false);
  const perPage = ref(6);
  const orderBy = ref("name");
  const orderByMethod = ref("asc");
  const items = ref([]);
  const loading = ref(false);

  const normalizeResponse = (response) => {
    const responseData = response.data?.data || response.data;
    const metaData = response.data?.meta || response.meta;

    return {
      data: Array.isArray(responseData) ? responseData : [],
      meta: metaData || {}
    };
  };

  const loadItems = async (page = 1, extraParams = {}) => {
    loading.value = true;
    try {
      const params = {
        page,
        perPage: perPage.value,
        orderBy: orderBy.value,
        orderByMethod: orderByMethod.value,
        ...extraParams 
      };

      const response = await fetchFunction(params);
      const { data, meta } = normalizeResponse(response);

      items.value = data;
      currentPage.value = meta?.current_page || 1;
      lastPage.value = meta?.last_page || 1;
      hasMorePages.value = (meta?.current_page || 1) < (meta?.last_page || 1);

    } catch (error) {
      console.error("Error al cargar datos:", error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    currentPage,
    lastPage,
    hasMorePages,
    perPage,
    orderBy,
    orderByMethod,
    items,
    loading,
    loadItems,
  };
}