import axios from '@/plugins/axios';

/**
 * Obtiene todas las circulares con filtros y paginación
 * @param {Object} params - Parámetros de filtrado
 * @param {string} [params.search] - Término de búsqueda
 * @param {string} [params.orderBy] - Campo de ordenamiento
 * @param {string} [params.orderByMethod] - Método de orden (asc/desc)
 * @param {number} [params.perPage] - Items por página
 * @param {number} [params.status] - Estado de la circular
 * @returns {Promise} Promesa con la respuesta paginada
 */
export const fetchAllCirculars = async (params = {}) => {
  const response = await axios.get('/circulars', {
    params: {
      search: params.search || null,
      orderBy: params.orderBy || null,
      orderByMethod: params.orderByMethod || null,
      perPage: params.perPage || null,
      status: params.status || null
    }
  });
  return response.data;
};

/**
 * Obtiene todas las circulares con filtros opcionales.
 * @param {Object} params - Parámetros para filtrar las circulares.
 * @returns {Promise} Promesa con los resultados de las circulares.
 */
export const fetchCirculars = async (params = {}) => {
  const response = await axios.get('/circulars/search', { params });
  return response.data;
};

/**
 * Obtiene los temas de las circulares.
 * @returns {Promise} Promesa con los temas.
 */
export const fetchCircularTopics = async () => {
  const response = await axios.get('/circulars/topics');
  return response.data;
};


/**
 * Obtiene los detalles de una circular específica.
 * @param {number|string} circularId - ID de la circular.
 * @returns {Promise} Promesa con los detalles de la circular.
 */
export const fetchCircularDetails = async (circularId) => {
  const response = await axios.get(`/circulars/${circularId}`);
  return response.data;
};

/**
 * Obtiene los roles relacionados con circulares
 * @returns {Promise} Promesa con los roles
 */
export const fetchCircularRoles = async () => {
  const response = await axios.get('/circulars/circular-roles');
  return response.data;
};