<template>
  <div id="app">
    <HeaderComponent />

    <main>
      <router-view></router-view>
    </main>

    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "@/components/header/HeaderComponent.vue";
import FooterComponent from "@/components/footer/FooterComponent.vue";
import '@/assets/styles/global.css';
export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
  },
   watch: {
    $route(to) {
      this.toggleHtmlOverflow(to);
    }
  },
  created() {
    this.toggleHtmlOverflow(this.$route);
  },
  methods: {
    toggleHtmlOverflow(route) {
      const htmlElement = document.documentElement;
      if (route.path.includes('/cursos/')) {
        htmlElement.classList.add('html-no-scroll');
        window.scrollTo({
          top: 0,
          behavior: 'auto' 
        });
      } else {
        htmlElement.classList.remove('html-no-scroll');
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}

footer {
  background-color: #f5f5f5;
  padding: 10px;
  color: #333;
}
</style>