<template>
   <div :class="{ 'sectionOneContainer': hasGroupedMembers }">
    <div class="row" v-if="mainMember">
      <div class="col-12 col-md-4 text-center"></div>
      <div class="col-12 col-md-4 text-center">
        <div class="persona">
          <img :src="mainMember.image" :alt="`${mainMember.name} ${mainMember.lastname}`" />
          <h5 class="cargoCustom">{{ mainMember.position }}</h5>
          <h5 class="labelCustom">{{ mainMember.label }} {{ mainMember.lastname }}</h5>
        </div>
      </div>
      <div class="col-12 col-md-4 text-center"></div>
    </div>

    <div v-for="(group, index) in groupedMembers" :key="index" class="row justify-content-md-center">
      <div
        v-for="member in group"
        :key="member.id"
        class="col-12 col-md-4 text-center"
        :class="{ 'offset-md-2': group.length === 2 && $index === 0 }"
      >
        <div class="persona">
          <img :src="member.image" :alt="`${member.name} ${member.lastname}`" />
          <h5 class="cargoCustom">{{ member.position }}</h5>
          <h5 class="labelCustom">{{ member.label }} {{ member.lastname }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "OrganizationalChartSectionOne",
  props: {
    committeeSection1: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const mainMember = computed(() => {
      return props.committeeSection1.reduce((lowest, member) => {
        return member.order < lowest.order ? member : lowest;
      }, props.committeeSection1[0]);
    });

    const groupedMembers = computed(() => {
      const filteredMembers = props.committeeSection1.filter((member) => member !== mainMember.value);
      
      return filteredMembers.reduce((result, member, index) => {
        const groupIndex = Math.floor(index / 3);
        if (!result[groupIndex]) result[groupIndex] = [];
        result[groupIndex].push(member);
        return result;
      }, []);
    });

    const hasGroupedMembers = computed(() => {
      return groupedMembers.value.length > 0;
    });

    return {
      mainMember,
      groupedMembers,
      hasGroupedMembers,
    };
  },
};
</script>

<style scoped>
.sectionOneContainer {
  border-bottom: 1px solid #00a0e1;
  margin-bottom: 35px;
}

.persona {
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.persona img {
  border-radius: 99em;
  border: 5px solid #eee;
  width: 140px;
  height: 140px;
  object-fit: cover;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .row {
    justify-content: center;
  }
  
  .col-md-4:only-child {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.labelCustom {
  color: #616161;
  font-weight: normal;
  margin-top: 5px;
}

.cargoCustom {
  color: #00a0e1;
  margin-top: 5px;
  font-weight: bold;
}
</style>