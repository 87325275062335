<template>
  <template v-if="!error">
    <div class="convenciones-container">
      <template v-if="isLoading">
        <div class="loading-container">
          <div class="loading-spinner"></div>
          <div class="loading-text">Cargando convenciones...</div>
        </div>
      </template>

      <template v-else>
        <template v-if="conventions.length > 0">
          <div
            v-for="(convention, index) in conventions"
            :key="convention.id"
            class="convention-block"
          >
            <h2>{{ convention.name }}</h2>
            <div class="convencion-item">
              <div class="convencion-info">
                <span class="label">Lugar:</span>
                <span class="value">{{
                  convention.place || "No especificado"
                }}</span>
              </div>
              <div class="convencion-info">
                <span class="label">Fecha:</span>
                <span class="value">{{
                  formatDate(convention.start_datetime)
                }}</span>
              </div>
            </div>

            <div
              v-if="convention.schedule_image"
              class="schedule-image-container"
            >
              <img
                :src="`${config.backendUrl}/${convention.schedule_image}`"
                :alt="`Cronograma ${convention.name}`"
                class="full-width-image"
              />
            </div>

            <div class="conferencias-list">
              <template v-if="isAuthenticated">
                <div
                  v-for="(
                    conference, conferenceIndex
                  ) in convention.conferences"
                  :key="conference.id"
                  class="conference-item"
                >
                  <ConferenceComponent
                    :conference="conference"
                    :primary-color="conference.primary_color"
                    :secondary-color="conference.secondary_color"
                    :audience-Target="conference.audience_target"
                    :conference-id="conference.id"
                    :index="conferenceIndex"
                    :registration-end-date="convention.registration_end_date"
                  />
                </div>
              </template>
            </div>

            <div
              v-if="index < conventions.length - 1"
              class="convention-divider"
            ></div>
          </div>
        </template>

        <template v-else>
          <div class="no-conventions">No hay convenciones disponibles.</div>
        </template>
      </template>
    </div>
  </template>
</template>
<script setup>
import { ref, onMounted, computed, watch } from "vue";
import config from "@/config";
import { useStore } from "vuex";
import conventionService from "@/services/api/convention";
import ConferenceComponent from "@/views/servicios/components/socios/convencion/ConferenceComponent.vue";

const store = useStore();
const isLoading = ref(true);
const error = ref(null);

const isAuthenticated = computed(() => !!store.state.user);

const conventions = ref([]);

const concessionaireId = computed(
  () => store.getters.getSelectedConcessionaire?.id
);

const fetchConventions = async () => {
  try {
    isLoading.value = true;
    error.value = null;

    if (!concessionaireId.value) {
      throw new Error("No se ha seleccionado un concesionario.");
    }

    const response = await conventionService.getConventionInformation(
      concessionaireId.value
    );

    conventions.value = response.data;
  } catch (err) {
    error.value =
      err.response?.data?.message ||
      err.message ||
      "Error al cargar las convenciones";
    console.error("Error fetching conventions:", err);
  } finally {
    isLoading.value = false;
  }
};

const formatDate = (dateString) => {
  if (!dateString) return "No especificado";

  const date = new Date(dateString);
  if (isNaN(date)) return "Fecha inválida";

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

watch(concessionaireId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    fetchConventions();
  }
});

onMounted(fetchConventions);
</script>

<style scoped>
.convenciones-container {
  padding: 1rem;
  margin: 1rem 0;
}

h2 {
  color: #021f5b;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
  letter-spacing: 1.5px;
}

.convencion-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #afafaf05;
  border-radius: 6px;
  max-width: 600px;
  margin: 0 auto;
}

.convencion-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.label {
  font-weight: 600;
  color: #021f5b;
  font-size: 1.5rem;
}

.value {
  color: #7f8c8d;
  font-size: 1.4rem;
  font-weight: 500;
}

@media (max-width: 992px) {
  h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
  }

  .label {
    font-size: 1.3rem;
  }

  .value {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .conference-item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(200, 200, 200, 0) 0%,
      rgba(163, 163, 163, 0.5) 50%,
      rgba(200, 200, 200, 0) 100%
    );
    margin: 0.75rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .conference-item:not(:last-child) {
    margin-bottom: 1rem;
    border: solid;
  }
  h2 {
    font-size: 2rem;
  }

  .convencion-info {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .label,
  .value {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.75rem;
  }

  .label,
  .value {
    font-size: 1.1rem;
  }

  .convencion-item {
    padding: 0.75rem;
    gap: 0.75rem;
  }
  
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 1rem;
  animation: fadeIn 0.5s ease-in;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  color: #2c3e50;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .loading-spinner {
    width: 40px;
    height: 40px;
    border-width: 3px;
  }

  .loading-text {
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .convenciones-container {
    padding: 0.5rem;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 0.75rem;
  }

  .convencion-item {
    width: 100%;
    padding: 0.5rem;
    gap: 0.75rem;
  }

  .convencion-info {
    gap: 0.25rem;
    width: 100%;
  }

  .label,
  .value {
    font-size: 1rem !important;
    line-height: 1.4;
  }

  .loading-spinner {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }

  .loading-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .convenciones-container {
    padding: 0.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

.convencion-info {
  flex-wrap: wrap;
}

.value {
  word-break: break-word; /* Evitar overflow de texto */
}

.conferencias-list {
  margin-top: 1rem;
}

.conference-item {
  transition: opacity 0.3s ease;
}

.schedule-image-container {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.full-width-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  padding: 1.6rem 1.6rem 0 1.6rem;
}
</style>