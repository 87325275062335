<template>
  <div class="card-image"
   :class="{ 'left-radius': isEven, 'right-radius': !isEven }"
  >
    <div 
      class="image-frame"
      :style="frameStyles"
    >
      <img 
         :src="imageUrl"
        :alt="altText" 
        class="event-image"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed  } from 'vue';
import config from "@/config"; 

const props = defineProps({
  image: { 
    type: String,
    required: true
  },
  altText: {  
    type: String,
    default: ''
  },
  secondaryColor: {
    type: String,
    default: '#9760C7'
  },
  primaryColor: {
    type: String,
    default: '#57025b'
  },
  isEven: {
    type: Boolean,
    default: true
  }
});

const frameStyles = computed(() => {
  return props.isEven 
    ? {
        '--before-deg': '5deg',
        '--after-deg': '-5deg'
      }
    : {
        '--before-deg': '355deg',
        '--after-deg': '5deg'
      };
});

const imageUrl = computed(() => {
  return `${config.backendUrl}/${props.image}`;
});
</script>

<style scoped>
.event-image {
  transition: transform 0.3s ease;
}

.card-image.left-radius {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.card-image.right-radius {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

@media (prefers-color-scheme: dark) {
  .image-frame::before,
  .image-frame::after {
    background: #6d48c7;
  }
}


.card-image {
  flex: 0 0 35%; 
  background: v-bind(secondaryColor);
  overflow: hidden;

}

.image-frame {
  position: relative;
  padding: 90px 0px;
  height: 100%;
  box-sizing: border-box;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  border: 5px solid v-bind(secondaryColor);
}

.image-frame::before,
.image-frame::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 90px;
  background: #9760c7;
  z-index: 0;
}

.image-frame::before {
  top: 0;
  background: linear-gradient(
    var(--before-deg), 
    v-bind(secondaryColor) 49%, 
    v-bind(primaryColor) 50%
  );
}

.image-frame::after {
  bottom: 0;
  background: linear-gradient(
    var(--after-deg), 
    v-bind(primaryColor) 49%, 
    v-bind(secondaryColor) 50%
  );
}

/* Responsive */
@media (max-width: 992px) {
  .card-image {
    flex: none;
    height: 400px;
  }

  .image-frame {
    padding: 60px 10px;
  }

  .image-frame::before,
  .image-frame::after {
    height: 60px;
  }
}

@media (max-width: 768px) {
  .card-image {
    height: 300px;
  }

  .image-frame {
    padding: 40px 10px;
  }

  .image-frame::before,
  .image-frame::after {
    height: 40px;
  }
}

@media (max-width: 480px) {
  .card-image {
    height: 250px;
  }

  .card-image.left-radius {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 0px;
}

}
</style>