<template>
  <footer v-if="isActive">
    <ContactoSection :contact="footerData.contact" />
    <LegalSection 
      :contact="footerData.contact" 
      :social="footerData.social"
      :currentYear="currentYear"
    />
  </footer>
</template>

<script>
import ContactoSection from '@/components/footer/ContactoSection.vue';
import LegalSection from '@/components/footer/LegalSection.vue';
import { footer } from '@/services/api/footer';

export default {
  name: "FooterComponent",
  components: {
    ContactoSection,
    LegalSection
  },
  data() {
    return {
      footerData: footer,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isActive() {
      return this.footerData.status === 2;
    },
  },
};
</script>