import axios from '@/plugins/axios';

export const fetchRegionals = async (params) => {
    try {
        const response = await axios.get('/regionals', {
            params
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching headers:', error);
        throw error;
    }
};