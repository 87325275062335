import { vehicleTypes } from "@/services/api/vehicleTypes.js";

export function useVehicleTypes() {
  const getVehicleTypeName = (type) => {
    const foundType = vehicleTypes.find((t) => t.id === String(type));
    return foundType ? foundType.name : "N/A";
  };

  return {
    getVehicleTypeName
  };
}