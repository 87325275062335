<template>
  <div :class="{ 'sectionOtherContainer': hasSection3Content }">
    <div class="row text-center custom-row">
      <div
        v-for="member in filteredCommitteeSection2"
        :key="member.id"
        class="col-12 col-md-4 mb-4"
      >
        <div class="persona">
          <h5 class="cargoCustom">{{ member.position }}</h5>
          <h5 class="labelCustom">
            {{ member.label }} {{ member.lastname }}, {{ member.name }} 
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row text-center custom-row">
      <div
        v-for="member in filteredCommitteeSection3"
        :key="member.id"
        class="col-12 col-md-4 mb-4"
      >
        <div class="persona">
          <h5 class="cargoCustom">{{ member.position }}</h5>
          <h5 class="labelCustom">
            {{ member.label }} {{ member.lastname }}, {{ member.name }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "OrganizationalChartSectionOther",
  props: {
    committeeSection2: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const filteredCommitteeSection2 = computed(() =>
      props.committeeSection2.filter((member) => member.section === "2")
    );

    const filteredCommitteeSection3 = computed(() =>
      props.committeeSection2.filter((member) => member.section === "3")
    );

    const hasSection3Content = computed(() => {
      return filteredCommitteeSection3.value.length > 0;
    });

    return {
      filteredCommitteeSection2,
      filteredCommitteeSection3,
      hasSection3Content,
    };
  },
};
</script>

<style scoped>
.sectionOtherContainer {
  border-bottom: 1px solid #00a0e1;
  margin-bottom: 35px;
}

.persona {
  margin-bottom: 45px;
}

.labelCustom {
  color: #616161;
  font-weight: normal;
  margin-top: 5px;
}

.cargoCustom {
  color: #00a0e1;
  margin-top: 5px;
  font-weight: bold;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 calc(33.333% - 30px);
    max-width: calc(33.333% - 30px);
  }
  
  .custom-row:not(:empty) {
    justify-content: center;
  }
  
  .custom-row > :only-child {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .custom-row > :nth-last-child(2):first-child,
  .custom-row > :nth-last-child(2):first-child ~ * {
    flex: 0 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 767px) {
  .custom-row {
    gap: 15px;
  }
}
</style>