<template>
  <section class="welcome-container">
    <div class="container">
      <div v-if="isLoading" class="loading-overlay">
        <div class="spinner"></div>
      </div>
      <div v-else>
        <div class="row text-center">
          <div class="col-12">
            <h2 class="welcome-title">BIENVENIDO/A</h2>
          </div>
        </div>
        <div class="row align-items-center special-container">
          <div class="col-12 col-md-4 text-center logo-container">
            <img :src="logo" alt="Logo" class="logo" />
          </div>
          <div class="col-12 col-md-6 info-container">
            <div class="row">
              <div class="col-12">
                <p class="company-name text-center">
                  {{ userName }} - {{ concessionaireName }}
                </p>
                <AdminNames
                  :admin-label="adminLabel"
                  :administrators="administrators"
                />
              </div>
              <div class="col-12 col-md-12 text-start ms-5">
                <p>
                  CUIT: <strong>{{ cuit }}</strong>
                </p>
                <p>
                  CARGO: <strong>{{ positionNames }}</strong>
                </p>
                <p>
                  DIRECCIÓN: <strong>{{ streetName }}</strong>
                </p>
                <p>
                  CIUDAD: <strong>{{ localityAndProvince }}</strong>
                </p>
                <p>
                  Teléfono: <strong>{{ phone }}</strong>
                </p>
                <p>
                  Email:
                  <a class="email-text" :href="`mailto:${email}`">
                    {{ email }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-12">
            <p class="welcome-text">
              Acá vas a poder encontrar información actualizada y completa para
              llevar adelante un mejor desarrollo de tu negocio.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import AdminNames from "./AdminNames.vue"

const store = useStore();
const isLoading = ref(false);

const selectedConcessionaire = computed(
  () => store.state.selectedConcessionaire
);

const userName = computed(() => {
  const name = store.state.user?.name || "";
  const lastname = store.state.user?.lastname || "";
  return `${name} ${lastname}`.trim();
});

const administrators = computed(
  () => selectedConcessionaire.value?.administrators || []
);

const adminLabel = computed(() => {
  if (administrators.value.length === 1) return "TU ADMINISTRADOR";
  if (administrators.value.length > 1) return "TUS ADMINISTRADORES";
  return "";
});

const concessionaireName = computed(
  () => selectedConcessionaire.value?.business_name || ""
);
const positionNames = computed(
  () =>
    store.state.user?.position?.map((pos) => pos.name).join(" - ") ||
    "Sin cargo asignado"
);

const cuit = computed(() => selectedConcessionaire.value?.cuit || "");
const streetName = computed(
  () => selectedConcessionaire.value?.street_name || ""
);
const localityAndProvince = computed(
  () =>
    `${selectedConcessionaire.value?.locality_name || ""}, ${
      selectedConcessionaire.value?.province_name || ""
    }`
);
const phone = computed(() => selectedConcessionaire.value?.phone || "");
const email = computed(() => selectedConcessionaire.value?.email || "");
const logo = computed(
  () =>
    selectedConcessionaire.value?.brand_logo ||
    require("@/assets/images/ACARA-logo-fondoblanco.png")
);

watch(selectedConcessionaire, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    isLoading.value = true;
    setTimeout(() => {
      isLoading.value = false;
    }, 1000);
  }
});
</script>

<style scoped>
* {
  font-family: "Inter", sans-serif;
}
.welcome-container {
  background: #005fa8;

  z-index: 2;
  min-height: auto;
}

.special-container {
  margin-bottom: 50px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: stretch !important;
  text-align: center;
  min-height: auto;
}

.logo-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.logo {
  max-width: 100%;
  height: auto;
}

.info-container {
  background: #efefef;
  color: black;
  padding: 10px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.company-name {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

a {
  color: #ffcc00;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.welcome-title {
  font-family: Inter;
  font-size: 46px;
  font-weight: 700;
  line-height: 77.76px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

.welcome-text {
  margin-bottom: 30px;
  font-size: 27px;
  font-weight: 400;
  line-height: 38.73px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: white;
}

@media (max-width: 768px) {
  .welcome-below {
    order: 2;
  }

  .info-container {
    padding: 15px;
  }

  .company-name {
    font-size: 1.2rem;
  }

  .welcome-title {
    font-size: 1.5rem;
  }

  .welcome-text {
    font-size: 0.9rem;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #054e8f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.email-text {
  color: blue;
  font-size: 0.9rem;
}

.email-text a {
  color: blue;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .special-container {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    text-align: center;
  }

  .logo-container {
    border-radius: 50px;
    margin-bottom: 20px;
    margin: 10px 10px 20px 10px;
    width: 90%;
    padding: 10px;
  }

  .info-container {
    border-radius: 50px;
    padding: 15px;
    width: 90%;
    text-align: center;
  }

  .company-name {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .welcome-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .welcome-text {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 10px 15px;
  }

  .logo {
    width: 60%;
    height: auto;
  }

  .loading-overlay {
    background: rgba(255, 255, 255, 0.9);
  }
}

@media (max-width: 480px) {
  .special-container {
    margin-bottom: 20px;
  }

  .logo-container {
    padding: 8px;
  }

  .info-container {
    padding: 10px;
    width: 90%;
    margin: 10px 10px 20px 10px;
  }

  .welcome-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .welcome-text {
    font-size: 0.85rem;
    margin: 5px 13px 20px;
  }

  .company-name {
    font-size: 0.8rem;
  }

  .logo {
    width: 50%;
  }
}
</style>
