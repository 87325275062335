<template>
  <div class="usuarios-view">
    <header class="header">
      <h1>ADMINISTRADOR</h1>
    </header>
    <div class="table-container">
      <div
        class="table-header d-flex justify-content-between align-items-center"
      >
        <h2>Sucursales</h2>
      </div>
      <div class="mb-3">
        <input
          type="text"
          v-model="searchTerm"
          class="form-control"
          placeholder="Buscar..."
        />
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>CUIT</th>
              <th>Razón Social</th>
              <th>Provincia</th>
              <th>Localidad</th>
              <th>Regional</th>
              <th>Calle</th>
              <th>Rubro</th>
              <th>Marca</th>
              <th>Estado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" class="loading-row">
              <td colspan="10" class="text-center py-4">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Cargando...</span>
                </div>
              </td>
            </tr>
            <template v-else>
              <tr
                v-for="concessionaire in concessionaires"
                :key="concessionaire.id"
              >
                <td>{{ concessionaire.cuit || "N/A" }}</td>
                <td>
                  <template
                    v-if="
                      concessionaire.type !== 1 && concessionaire.central_house
                    "
                  >
                    {{ concessionaire.central_house.business_name }} -
                    {{ concessionaire.business_name }}
                  </template>
                  <template v-else>
                    {{ concessionaire.business_name || "Nombre no disponible" }}
                  </template>
                </td>
                <td>{{ concessionaire.province_name || "N/A" }}</td>
                <td>{{ concessionaire.locality_name || "N/A" }}</td>
                <td>{{ concessionaire.regional_name || "N/A" }}</td>
                <td>{{ concessionaire.street_name || "N/A" }}</td>
                <td>{{ getVehicleTypeName(concessionaire.sector) }}</td>
                <td>{{ getBrandNames(concessionaire.brands) }}</td>
                <td
                  :class="
                    concessionaire.status === 1
                      ? 'status-enabled'
                      : 'status-disabled'
                  "
                >
                  {{
                    concessionaire.status === 1 ? "Habilitado" : "Inhabilitado"
                  }}
                </td>

                <td class="text-center position-relative">
                  <div class="dropdown">
                    <button
                      class="btn btn-link p-0"
                      type="button"
                      @click.stop="toggleDropdown(concessionaire.id)"
                      :aria-expanded="dropdownVisible === concessionaire.id"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <ul
                      v-if="dropdownVisible === concessionaire.id"
                      class="dropdown-menu show"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          @click.prevent="editConcessionaire(concessionaire)"
                        >
                          <i class="fas fa-edit"></i> Editar
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <div v-if="!isLoading && concessionaires.length === 0" class="no-results">
        No se encontraron sucursales.
      </div>

      <PaginationComponent
        :currentPage="currentPage"
        :lastPage="lastPage"
        :hasMorePages="hasMorePages"
        @pageChange="loadUsersWithSearch"
        class="pagination-container"
      />
    </div>

    <ConcessionaireFormModal
      v-if="showConcessionaireModal"
      :isVisible="showConcessionaireModal"
      :concessionaire="selectedConcessionaire"
      @close="closeConcessionaireModal"
      @success="handleModalSuccess"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch, nextTick, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import ConcessionaireFormModal from "./components/ConcessionaireFormModal.vue";
import { fetchConcessionaires } from "@/services/api/concessionaire.js";
import usePagination from "@/composables/usePagination";
import PaginationComponent from "@/components/common/PaginationComponent.vue";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";
import { debounce } from "lodash";
import { useVehicleTypes } from "@/composables/useVehicleTypes";

export default {
  name: "AdministradorUsuariosView",
  components: {
    PaginationComponent,
    ConcessionaireFormModal,
  },
  setup() {
    const store = useStore();

    const showConcessionaireModal = ref(false);
    const selectedConcessionaire = ref(null);

    const isLoading = ref(false);
    const searchTerm = ref("");
    const pagination = usePagination(fetchConcessionaires);
    const { currentPage, lastPage, hasMorePages } = pagination;
    const concessionaires = pagination.items;
    const loadItems = pagination.loadItems;

    const modalOpen = ref(false);
    const selectedUser = ref(null);

    const selectedConcessionaireId = computed(
      () => store.getters.getSelectedConcessionaire?.id
    );

    const showUserRegistrationModal = ref(false);
    const isEditing = ref(false);

    const { getVehicleTypeName } = useVehicleTypes();

    const dropdownVisible = ref(null);

    const toggleDropdown = (concessionaireId) => {
      dropdownVisible.value =
        dropdownVisible.value === concessionaireId ? null : concessionaireId;
    };

    const closeDropdown = () => {
      dropdownVisible.value = null;
    };

    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        closeDropdown();
      }
    };

    const getBrandNames = (brands) => {
      if (!brands || brands.length === 0) return "N/A";
      return brands.map((brand) => brand.brand_name).join(", ");
    };

    const openViewsModal = (concessionaire) => {
      selectedUser.value = concessionaire;
      modalOpen.value = true;
    };

    const handleModalClose = () => {
      modalOpen.value = false;
    };

    const handleModalSuccess = (message) => {
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: message,
      });
      modalOpen.value = false;
      loadUsersWrapper(currentPage.value, {
        search: searchTerm.value,
        type: 2,
      });
    };

    const openUserRegistrationModal = (editing, concessionaire = null) => {
      isEditing.value = editing;
      selectedUser.value = concessionaire;
      showUserRegistrationModal.value = true;

      nextTick(() => {
        const modalElement = document.getElementById("modalAltaUsuario");
        if (modalElement) {
          const modalInstance = Modal.getOrCreateInstance(modalElement);
          modalInstance.show();
        }
      });
    };

    const closeUserRegistrationModal = () => {
      const modalElement = document.getElementById("modalAltaUsuario");
      if (modalElement) {
        const modalInstance = Modal.getInstance(modalElement);
        modalInstance?.hide();
      }
      showUserRegistrationModal.value = false;
    };

    const loadUsersWrapper = async (page, params = {}) => {
      if (!store.getters.isAuthenticated) return;
      isLoading.value = true;
      try {
        await loadItems(page, {
          ...params,
          concessionaireId: selectedConcessionaireId.value,
        });
      } finally {
        isLoading.value = false;
      }
    };

    const loadUsersWithSearch = (page) => {
      loadUsersWrapper(page, {
        search: searchTerm.value,
        type: 2,
      });
    };

    const editConcessionaire = (concessionaire) => {
      selectedConcessionaire.value = concessionaire;
      showConcessionaireModal.value = true;
    };

    const closeConcessionaireModal = () => {
      showConcessionaireModal.value = false;
      selectedConcessionaire.value = null;
    };

    const debouncedLoadUsers = debounce(() => {
      loadUsersWrapper(1, {
        search: searchTerm.value,
        type: 2,
      });
    }, 300);

    watch(searchTerm, () => {
      debouncedLoadUsers();
    });

    watch(selectedConcessionaireId, () => {
      if (store.getters.isAuthenticated) {
        loadUsersWrapper(1, {
          search: searchTerm.value,
          type: 2,
        });
      }
    }, { immediate: true }
    );

    onMounted(() => {
      loadUsersWrapper(1, { type: 2 });
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      closeDropdown,
      showConcessionaireModal,
      selectedConcessionaire,
      editConcessionaire,
      closeConcessionaireModal,
      dropdownVisible,
      toggleDropdown,
      isLoading,
      getBrandNames,
      getVehicleTypeName,
      debouncedLoadUsers,
      loadUsersWithSearch,
      isEditing,
      searchTerm,
      concessionaires,
      currentPage,
      lastPage,
      hasMorePages,
      modalOpen,
      selectedUser,
      openViewsModal,
      handleModalClose,
      handleModalSuccess,
      showUserRegistrationModal,
      openUserRegistrationModal,
      closeUserRegistrationModal,
    };
  },
};
</script>

<style scoped>
.usuarios-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f9;
}

.header {
  background-color: #001f5b;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
}

.header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.header h2 {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.table-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: #001f5b;
}

.nuevo-btn {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nuevo-btn:hover {
  background-color: #0056b3;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: left;
  font-size: 14px;
}

.table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  justify-content: center;
  vertical-align: middle;
}

.options {
  display: flex;
  gap: 10px;
}

.options button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: blue;
}

.options button .fas {
  font-size: 14px;
  color: #001f5b;
}

.options button.btn-edit .fas {
  color: #007bff;
}

.options button.btn-reset .fas {
  color: #ffc107;
}

.options button.btn-delete .fas {
  color: #dc3545;
}

.no-results {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 20px;
}

.table th {
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 768px) {
  .table-container {
    padding: 2rem;
  }

  .table th,
  .table td {
    font-size: 12px;
    padding: 5px;
    text-align: center;
  }

  .table th {
    white-space: nowrap;
  }

  .action-button {
    font-size: 10px;
    padding: 5px 8px;
  }

  .table-responsive {
    position: relative;
    z-index: 1;
    flex-grow: 1;
    overflow-y: auto;
    max-height: 500px;
    border: 1px solid #ddd;
  }

  .header {
    padding: 10px;
    font-size: 18px;
  }

  .table-header h2 {
    font-size: 16px;
  }

  .nuevo-btn {
    padding: 6px 10px;
    font-size: 12px;
  }
}

.btn-new {
  margin: 0;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #0056b3;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.875rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn {
  margin-top: 0;
}

.btn-new:hover {
  background-color: #003d80;
  transform: translateY(-2px);
}

.btn-new:active {
  background-color: #002f63;
  transform: translateY(0);
}

.table-header {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.table-header h2 {
  margin: 0;
  font-weight: bold;
  font-size: 1.25rem;
}

@media (max-width: 768px) {
  .btn-new {
    width: 100%;
    text-align: center;
  }

  .table-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.pagination-container {
  margin-top: 10px;
  text-align: center;
}

.btn-link {
  color: #6c757d;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-link:hover {
  background-color: rgba(0, 123, 255, 0.1);
  color: #0056b3;
}

.btn-link:focus {
  box-shadow: none;
}

.dropdown {
  position: relative !important; /* Fundamental para el posicionamiento */
}

.dropdown-menu {
  position: absolute !important;
  right: -10px !important;
  left: auto !important;
  top: 100% !important;
  z-index: 1001 !important; /* Mayor que el z-index de la tabla */
  min-width: 140px;
  transform: translateY(5px) !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
}

.table-responsive {
  position: static !important;
  overflow-x: auto;
}

.table-container {
  position: relative;
  z-index: 1;
}
</style>
