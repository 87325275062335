export const sucursalSlides = [
  {
    id: 1,
    region: "Parte 1",
    images: [
      { src: "BsAs - Atlantico.png", alt: "BsAs - Atlántico" },
      { src: "COMAHUE - Neuquen.png", alt: "Comahue - Neuquén" },
      { src: "Cordoba.png", alt: "Córdoba" },
      { src: "Corrientes.png", alt: "Corrientes" },
    ],
  },
  {
    id: 2,
    region: "Parte 2",
    images: [
      { src: "BsAs - Centro.png", alt: "BsAs - Centro" },
      { src: "Cuyo - Mendoza.png", alt: "Cuyo - Mendoza" }, 
      { src: "NOA - Santiago del Estero.png", alt: "NOA - Santiago del Estero" },
      { src: "Entre Rios.png", alt: "Entre Ríos" },
    ],
  },
  {
    id: 4,
    region: "Parte 3",
    images: [
      { src: "BsAs - GBA Sur.png", alt: "BsAs - GBA Sur" },
      { src: "CUYO - San Juan.png", alt: "Cuyo - San Juan" }, 
      { src: "La Pampa.png", alt: "La Pampa" },
      { src: "NEA - Chaco_OK.png", alt: "NEA - Chaco" },
    ],
  },
  {
    id: 5,
    region: "Parte 4",
    images: [
      { src: "BsAs - La Plata.png", alt: "BsAs - La Plata" },
      { src: "Jujuy.png", alt: "Jujuy" },
      { src: "CUYO - San Luis.png", alt: "Cuyo - San Luis" },
      { src: "NEA - Misiones.png", alt: "NEA - Misiones" },
    ],
  },
  {
    id: 6,
    region: "Parte 5",
    images: [
      { src: "BsAs - Zona Norte.png", alt: "BsAs - Zona Norte" },
      { src: "Patagonia - Tierra del Fuego.png", alt: "Patagonia - Tierra del Fuego" },
      { src: "NOA - Tucuman.png", alt: "NOA - Tucumán" },
      { src: "Formosa.png", alt: "Formosa" },
    ],
  },
  {
    id: 7,
    region: "Parte 6",
    images: [
      { src: "BsAs - Zona Sur.png", alt: "BsAs - Zona Sur" },
      { src: "NOA - Catamarca.png", alt: "NOA - Catamarca" },
      { src: "Patagonia - Santa cruz.png", alt: "Patagonia - Santa Cruz" },
      { src: "Santa Fe.png", alt: "Santa Fe" },      
    ],
  },
  {
    id: 8,
    region: "Parte 7",
    images: [
      { src: "BsAs - Zona Oeste.png", alt: "BsAs - Zona Oeste" },
      { src: "Patagonia - Rio negro.png", alt: "Patagonia - Río Negro" },
      { src: "Corrientes.png", alt: "Corrientes" }, // Repetido
      { src: "NOA - Salta.png", alt: "NOA - Salta" },
    ],
  },  
  {
    id: 9,
    region: "Parte 8",
    images: [
      { src: "Capital Federal.png", alt: "Capital Federal" },
      { src: "La Rioja.png", alt: "La Rioja" },
      { src: "Patagonia - Chubut.png", alt: "Patagonia - Chubut" },
      { src: "Entre Rios.png", alt: "Entre Ríos" }, // Repetido
    ],
  },
];